import React from "react"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { useNavigate } from 'react-router'
import { required } from 'redux-form-validators'
import { createStructuredSelector } from 'reselect'
import { textField } from '../../fieldRenderers/textField'
import { textAreaField } from '../../fieldRenderers/textAreaField'
import { createNewSignalChain } from '../../actions/signalChainActions'

let NewSignalChainForm = ({ handleSubmit }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <Field label="Name" name='signal_chain[name]' component={textField} type='text' validate={[required()]}/>
      <Field label="Description" name='signal_chain[description]' component={textAreaField} validate={[required()]}/>
      <div className="form-group" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' value="Create Signal Chain"/>
      </div>
    </form>
  )
}

NewSignalChainForm = reduxForm({
  form: 'new_signal_chain'
})(NewSignalChainForm)

const NewSignalChainModal = ({ submit, errors }) => {
  const navigate = useNavigate()
  return (
    <div className="form-modal">
      <div className="form-modal-body">
        <div className="form-modal-header">
          <h2>Create a New Signal Chain</h2>
          { errors }
        </div>
        <NewSignalChainForm onSubmit={ submit(navigate) }/>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  errors: state => console.log(state)
})

const mapDispatchToProps = dispatch => {
  return {
    submit: navigate => params => dispatch(createNewSignalChain(params, navigate)),
  }
}

export default connect(selector, mapDispatchToProps)(NewSignalChainModal);