import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { submitUserSettingsForm } from '../../../actions/userActions'
import { textField } from '../../../fieldRenderers/textField'
import { textAreaField } from '../../../fieldRenderers/textAreaField'
import { radioPanel } from '../../../fieldRenderers/radioPanel'
import { searchableAjaxMultiSelect } from '../../../fieldRenderers/searchableAjaxMultiSelect'
import { gearSelectionField } from '../../../fieldRenderers/gearSelectionField'
import { imageUploadField } from '../../../fieldRenderers/imageUploadField'
import { required, email } from 'redux-form-validators'
import { alphanumeric, nameCharacters } from '../../../fieldRenderers/validators'
import { transformKeysCamelToSnake } from '../../../utils/documentUtils'

import GuitarIcon from '../../../images/Icon_Guitar.svg'
import KeyboardIcon from '../../../images/Icon_Keyboard.svg'
import BassIcon from '../../../images/Icon_Bass.svg'

let UserForm = ({ handleSubmit, error }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit } style={{gap: '24px'}}>
      <Field label="Profile Photo" name='user[avatar]' component={imageUploadField} />
      <div className="flex-row">
        <Field label="First Name" name='user[first_name]' component={textField} type='text' validate={[required(), alphanumeric]}/>
        <Field label="Last Name" name='user[last_name]' component={textField} type='text'/>
      </div>
      <Field label="Username" name='user[username]' component={textField} type='text' validate={[required(), alphanumeric]}/>
      <Field label="Email" name='user[email]' component={textField} type='email' validate={[required(), email()]}/>
      <Field label="Bio" name='user[bio]' component={textAreaField} placeholder="Enter Bio" />
      <div className="form-group">
        <label>Primary Instrument</label>
        <div className="radio-panel-group">
          <Field label="Guitar" name='user[primary_instrument]' id="user_instrument_guitar" component={radioPanel} type='radio' value="guitar" image={GuitarIcon} />
          <Field label="Keyboard" name='user[primary_instrument]' id="user_instrument_keyboard" component={radioPanel} type='radio' value="keyboard" image={KeyboardIcon} />
          <Field label="Bass" name='user[primary_instrument]' id="user_instrument_bass" component={radioPanel} type='radio' value="bass" image={ BassIcon} />
        </div>
      </div>
      <Field name="user[genres]" component={searchableAjaxMultiSelect} useEditButton={true} dataUrl={'/api/v2/genres.json'} label="Music Preferences"/>
      <Field name="user[daws]" component={searchableAjaxMultiSelect} useEditButton={true} dataUrl={'/api/v2/daws.json'} label="DAWs Used"/>
      <div className="form-group">
        <label>Favorite Gear</label>
        <div className="favorites-group">
          <div className="favorite">
            <Field name="user[favorite_instrument_id]" component={gearSelectionField} formName='user_settings' gearType='instrument' backupLabel="Favorite Instrument" multiple={false}/>
          </div>
          <div className="favorite">
            <Field name="user[favorite_pedal_id]" component={gearSelectionField} formName='user_settings' gearType='pedal' backupLabel="Favorite Pedal" multiple={false}/>
          </div>
          <div className="favorite">
            <Field name="user[favorite_amp_id]" component={gearSelectionField} formName='user_settings' gearType='amp' backupLabel="Favorite Amp" multiple={false}/>
          </div>
        </div>
      </div>
      <div className="form-group" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' value="Save Changes"/>
      </div>
    </form>
  )
}

UserForm = reduxForm({
  form: 'user_settings',
  enableReinitialize: true
})(UserForm)

UserForm = connect((state) => {
  console.log("current user in profile connect", state.user.currentUser)
  return { initialValues: {
    user: transformKeysCamelToSnake(state.user.currentUser || {})
  }
}})(UserForm)

const UserSettings = ({ submit }) => {
  return (
    <UserForm onSubmit={submit}/>
  )
}

const selector = createStructuredSelector({
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitUserSettingsForm(params)),
  }
}

export default connect(selector, mapDispatchToProps)(UserSettings)