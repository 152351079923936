import { waitFor } from '../utils/promises'

function openModal(modal, closable = true) {
  return function(dispatch, getState) {
    const doTheThing = () => {
      dispatch({ type: 'SET_MODAL', modal: modal, closable: closable })
      dispatch({ type: 'POSITION_MODAL', position: 'bottom' })
      dispatch({ type: 'ANIMATE_MODAL' })
      dispatch({ type: 'OPEN_MODAL' })
      waitFor(10).then(() => dispatch({ type: 'POSITION_MODAL', position: 'center' }))
    }
    if(!getState().modal.modalOpen) {
      if(getState().modal.modal == modal) {
        dispatch({ type: 'SET_MODAL', modal: 'NONE' })
        waitFor(10).then(doTheThing)
      }
      else {
        doTheThing()
      }
    }
    else {
      dispatch({ type: 'ANIMATE_MODAL' })
      dispatch({ type: 'POSITION_MODAL', position: 'bottom'})
      waitFor(500)
        .then(() => {
          dispatch({ type: 'SET_MODAL', modal: modal, closable: closable })
        })
        .then(() => waitFor(10))
        .then(() => dispatch({ type: 'POSITION_MODAL', position: 'CENTER' }))
    }
  }
}

function transitionModalRight(modal, closable = true) {
  return function(dispatch, getState) {
    dispatch({ type: 'POSITION_MODAL', position: 'left' })
    waitFor(500)
      .then(() => { 
        dispatch({type: 'UNANIMATE_MODAL'})
        dispatch({type: 'POSITION_MODAL', position: 'right'})
        dispatch({type: 'SET_MODAL', modal: modal, closable: closable})
      })
      .then(() => waitFor(50))
      .then(() => dispatch({type: 'ANIMATE_MODAL'}))
      .then(() => waitFor(50))
      .then(() => dispatch({ type: 'POSITION_MODAL', position: 'center' }))
  }
}

function transitionModalLeft(modal, closable = true) {
  return function(dispatch, getState) {
    dispatch({ type: 'POSITION_MODAL', position: 'right' })
    waitFor(500)
      .then(() => { 
        dispatch({type: 'UNANIMATE_MODAL'})
        dispatch({type: 'POSITION_MODAL', position: 'left'})
        dispatch({type: 'SET_MODAL', modal: modal, closable: closable})
      })
      .then(() => waitFor(50))
      .then(() => dispatch({type: 'ANIMATE_MODAL'}))
      .then(() => waitFor(50))
      .then(() => dispatch({ type: 'POSITION_MODAL', position: 'center' }))
  }
}

function closeModal() {
  return function(dispatch, getState) {
    dispatch({ type: 'POSITION_MODAL', position: 'bottom' })
    waitFor(500).then(() => {
        dispatch({ type: 'CLOSE_MODAL' })
        dispatch({ type: 'SET_MODAL', modal: 'NONE' })
      })
  }
}

function toast(title, message = '') {
  return (dispatch, getState) => {
    dispatch({type: 'SET_TOAST', title: title, message: message})
    dispatch(openModal('TOAST', false))
    waitFor(2000).then(() => {
      dispatch(closeModal())
    })
  }
}

export { openModal, closeModal, transitionModalRight, transitionModalLeft, toast }