import React from "react"

const textField = ({ input, label, placeholder, type, className, icon, onIconClick, meta: { touched, error, active } }) => (
  <div className={className || "form-group"}>
    <label>{label}</label>
    <input {...input} placeholder={placeholder || label} type="text"/>
    { icon && React.createElement(icon, {onClick: onIconClick, className: 'icon'}) }
    {touched && error && !active && <span className="error">{error}</span>}
  </div>
)

export { textField }