import React, {useState, useEffect} from 'react'
import { Field } from 'redux-form'
import { textField } from './textField'
import { required } from 'redux-form-validators'

import TrashIcon from '../images/trash.svg'

const nameValueFieldArray = ({ fields, label, addLabel, meta: { error } }) => {
  return (
    <div className="form-group field-array">
      <div className="field-array-header">
        <label>{label}</label>
        <div className="add-button" onClick={() => fields.push()}>+ {addLabel}</div>
      </div>
      <div className="links-field-array">
        { fields.map((link, index) => (
          <div className="link-field" key={index}>
            <Field name={`${link}[name]`} component={textField} label={`${index+1}. Name`} className="title subfield" placeholder="Type here" validate={[required()]} type="text"/>
            <Field name={`${link}[value]`} component={textField} label="Value" className="link subfield" placeholder="Type here" validate={[required()]} icon={TrashIcon} onIconClick={() => fields.remove(index)} type="text"/>
          </div>
        ))}
      </div>
    </div>
  )
}

export { nameValueFieldArray }