import React from "react"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { useNavigate } from 'react-router'
import { required, email } from 'redux-form-validators'
import { createStructuredSelector } from 'reselect'
import { login } from '../../actions/userActions'
import { openModal } from '../../actions/modalActions'
import { textField } from '../../fieldRenderers/textField'
import { passwordField } from '../../fieldRenderers/passwordField'


let LoginForm = ({ handleSubmit }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <Field label="Email or Username" name='user[email]' component={textField} type='text' validate={[required()]}/>
      <Field label="Password" name='user[password]' component={passwordField} type='password' validate={[required()]}/>
      <div className="form-group" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' value="Login"/>
      </div>
    </form>
  )
}

LoginForm = reduxForm({
  form: 'login'
})(LoginForm)

const LoginModal = ({ signup, submit, loginError }) => {
  let navigate = useNavigate()
  return (
    <div className="login-modal">
      <div className="login-modal-image"></div>
      <div className="bolt"></div>
      <div className="login-modal-body">
        <div className="login-modal-header">
          <h2>Login</h2>
        { !loginError && 
          (
            <p>Fill in your credentials below to log in.</p>
          )
        }
        { loginError && 
          (
            <p className="error">{ loginError }</p>
          ) 
        }
        </div>
        <LoginForm onSubmit={ submit(navigate) }/>
        <hr/>
        <div className="omniauth-buttons">
          <div className="omniauth-facebook"><span className="icon"/>Continue with Facebook</div>
          <div className="omniauth-google"><span className="icon"/>Continue with Google</div>
        </div>
        <p className="login-link">Don't have an account? <a onClick={ signup }><strong>Sign Up</strong></a></p>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  loginError: state => state.user.loginError
})

const mapDispatchToProps = dispatch => {
  return {
    submit: navigate => params => dispatch(login(params, navigate)),
    signup: () => dispatch(openModal('SIGNUP'))
  }
}

export default connect(selector, mapDispatchToProps)(LoginModal);