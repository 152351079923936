const initialState = {
  genres: [],
  daws: []
}


function formDataReducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_FORM_DATA':
      return {...state, 
        genres: action.data.genres,
        daws: action.data.daws
      }
    default:
      return state
  }
}

export default formDataReducer
