import React from "react"
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { closeModal } from '../../actions/modalActions'
import { useNavigate } from 'react-router'

import PersonPinIcon from '../../images/person_pin.svg'
import CheckIcon from '../../images/check.svg'

const WelcomeModal = ({ firstName, complete, toProfile }) => {
  let navigate = useNavigate()
  return (
    <div className="welcome-modal">
      <div className="icon-frame">
        <PersonPinIcon/>
        <span className="check"><CheckIcon/></span>
      </div>
      <div className="message">
        <h2>Congrats for creating your account, {firstName}!</h2>
        <p>Now let's set up your profile quickly</p>
      </div>
      <div className="buttons">
        <div className="complete" onClick={() => complete(navigate)}>Complete My Profile</div>
        <div className="skip" onClick={() => toProfile(navigate)}>Skip to Profile</div>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  firstName: state => state.user.firstName
})

const mapDispatchToProps = dispatch => {
  return {
    complete: navigate => {
      dispatch(closeModal())
      navigate('/profile')
    },
    toProfile: navigate => {
      dispatch(closeModal())
      navigate('/profile')
    },
  }
}

export default connect(selector, mapDispatchToProps)(WelcomeModal);