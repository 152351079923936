import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import { Link, useNavigate } from 'react-router-dom'
import { openModal } from '../../../actions/modalActions'
import { loadSignalChains, unloadSignalChains, openSignalChainDetailsModal, openSignalChainInfoModal, deleteSignalChain } from '../../../actions/signalChainActions'
import PopupMenu from '../../../components/controls/PopupMenu'

import { timeFormatter } from '../../../utils/documentUtils'

import FolderOffIcon from '../../../images/folder_off.svg'
import MenuIcon from '../../../images/more_vert.svg'
import EditSignalChainIcon from '../../../images/pinch_zoom_out.svg'
import EditIcon from '../../../images/edit.svg'
import ShareIcon from '../../../images/share.svg'
import DuplicateIcon from '../../../images/file_copy.svg'
import TrashIcon from '../../../images/trash.svg'
import InfoIcon from '../../../images/info.svg'

const SignalChain = ({signalChain, otherProfile}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuButtonRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const editSignalChain = () => {
    navigate(`/signal_chains/${signalChain.id}`)
  }
  
  const editDetails = () => {
    dispatch(openSignalChainDetailsModal(signalChain))
  }
  
  const viewInfo = () => {
    dispatch(openSignalChainInfoModal(signalChain))
  }
  
  const share = () => {
  }

  const duplicate = () => {
  }
  
  const destroy = () => {
    dispatch(deleteSignalChain(signalChain))
  }
  
  return (
    <div className="signal-chain">
      <div className="image-panel">
        <div className="image">
          { (signalChain.gear.length <= 9) && signalChain.gear.map((scg, i) => {
            return (
              <div className="gear-image" key={ i }>
              { scg.image_url ? (
                <img src={ scg.image_url }/>
                ) : (
                <div className="blank-image"></div>
              )
              }
              </div>
            )
          })}
          { (signalChain.gear.length > 9) && signalChain.gear.slice(0, 4).map((scg, i) => {
            return (
              <div className="gear-image" key={ i }>
              { scg.image_url ? (
                <img src={ scg.image_url }/>
                ) : (
                <div className="blank-image"></div>
              )
              }
              </div>
            )
          })}
          { (signalChain.gear.length > 9) && (
            <div className="gear-image">+{signalChain.gear.length-8}</div>
          )}
          { (signalChain.gear.length > 9) && signalChain.gear.slice(-4).map((scg, i) => {
            return (
              <div className="gear-image" key={ i }>
              { scg.image_url ? (
                <img src={ scg.image_url }/>
                ) : (
                <div className="blank-image"></div>
              )
              }
              </div>
            )
          })}
          { (signalChain.gear.length) == 2 && (
            <div className="lines">
              <div className="small-horizontal-line" style={{top: '159px', left: '150px'}}/>
            </div>
          )}
          { (signalChain.gear.length) == 3 && (
            <div className="lines">
              <div className="small-horizontal-line" style={{top: '159px', left: '105px'}}/>
              <div className="small-horizontal-line" style={{top: '159px', left: '195px'}}/>
            </div>
          )}
          { (signalChain.gear.length) == 4 && (
            <div className="lines">
              <div className="small-horizontal-line"   style={{top: '114px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '114px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '150px', left: '250px'}}/>
              <div className="large-horizontal-line-1" style={{top: '159px', left: '159px'}}/>
              <div className="tiny-vertical-line"      style={{top: '160px', left: '159px'}}/>
            </div>
          )}
          { (signalChain.gear.length) == 5 && (
            <div className="lines">
              <div className="small-horizontal-line"   style={{top: '114px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '114px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '150px', left: '250px'}}/>
              <div className="large-horizontal-line-2" style={{top: '159px', left: '115px'}}/>
              <div className="tiny-vertical-line"      style={{top: '160px', left: '115px'}}/>
              <div className="small-horizontal-line"   style={{top: '204px', left: '150px'}}/>
            </div>
          )}
          { (signalChain.gear.length) == 6 && (
            <div className="lines">
              <div className="small-horizontal-line"   style={{top: '114px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '114px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '150px', left: '250px'}}/>
              <div className="large-horizontal-line-3" style={{top: '159px', left: '70px'}}/>
              <div className="tiny-vertical-line"      style={{top: '160px', left: '70px'}}/>
              <div className="small-horizontal-line"   style={{top: '204px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '204px', left: '195px'}}/>
            </div>
          )}
          { (signalChain.gear.length) == 7 && (
            <div className="lines">
              <div className="small-horizontal-line"   style={{top: '69px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '69px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '105px', left: '250px'}}/>
              <div className="large-horizontal-line-3" style={{top: '114px', left: '70px'}}/>
              <div className="tiny-vertical-line"      style={{top: '115px', left: '70px'}}/>
              <div className="small-horizontal-line"   style={{top: '159px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '159px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '195px', left: '250px'}}/>
              <div className="large-horizontal-line-1" style={{top: '204px', left: '159px'}}/>
              <div className="tiny-vertical-line"      style={{top: '205px', left: '159px'}}/>
            </div>
          )}
          { (signalChain.gear.length) == 8 && (
            <div className="lines">
              <div className="small-horizontal-line"   style={{top: '69px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '69px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '105px', left: '250px'}}/>
              <div className="large-horizontal-line-3" style={{top: '114px', left: '70px'}}/>
              <div className="tiny-vertical-line"      style={{top: '115px', left: '70px'}}/>
              <div className="small-horizontal-line"   style={{top: '159px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '159px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '195px', left: '250px'}}/>
              <div className="large-horizontal-line-2" style={{top: '204px', left: '115px'}}/>
              <div className="tiny-vertical-line"      style={{top: '205px', left: '115px'}}/>
              <div className="small-horizontal-line"   style={{top: '249px', left: '150px'}}/>
            </div>
          )}
          { (signalChain.gear.length) >= 9 && (
            <div className="lines">
              <div className="small-horizontal-line"   style={{top: '69px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '69px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '105px', left: '250px'}}/>
              <div className="large-horizontal-line-3" style={{top: '114px', left: '70px'}}/>
              <div className="tiny-vertical-line"      style={{top: '115px', left: '70px'}}/>
              <div className="small-horizontal-line"   style={{top: '159px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '159px', left: '195px'}}/>
              <div className="tiny-vertical-line"      style={{top: '195px', left: '250px'}}/>
              <div className="large-horizontal-line-3" style={{top: '204px', left: '70px'}}/>
              <div className="tiny-vertical-line"      style={{top: '205px', left: '70px'}}/>
              <div className="small-horizontal-line"   style={{top: '249px', left: '105px'}}/>
              <div className="small-horizontal-line"   style={{top: '249px', left: '195px'}}/>
            </div>
          )}
        </div>
        <div className={`overlay ${menuOpen ? 'open' : ''}`}>
          <div className="menu-button" ref={menuButtonRef} onClick={() => setMenuOpen(!menuOpen)}><MenuIcon/></div>
          { !otherProfile && (
            <PopupMenu className="signal-chain-menu" triggeringRef={menuButtonRef} closeCallback={() => setMenuOpen(false)}options={[
              { label: 'Edit Signal Chain', icon: EditSignalChainIcon, onClick: editSignalChain },
              { label: 'Edit Details', icon: EditIcon, onClick: editDetails },
              { label: 'View Info', icon: InfoIcon, onClick: viewInfo },
              { label: 'Share', icon: ShareIcon, onClick: share },
              { label: 'Duplicate', icon: DuplicateIcon, onClick: duplicate },
              { label: 'Delete', icon: TrashIcon, onClick: destroy }
            ]}/>
          )}
          { otherProfile && (
            <PopupMenu className="signal-chain-menu" triggeringRef={menuButtonRef} closeCallback={() => setMenuOpen(false)}options={[
              { label: 'View Info', icon: InfoIcon, onClick: viewInfo },
              { label: 'Share', icon: ShareIcon, onClick: share },
              { label: 'Duplicate', icon: DuplicateIcon, onClick: duplicate }
            ]}/>
          )}
        </div>
      </div>
      <div className="info">
        <h3>{ signalChain.name }</h3>
        { signalChain.genre && signalChain.genre.name && <span className="pill grey-pill">{signalChain.genre.name}</span> }
        <p>Created on { timeFormatter(signalChain.created_at) }</p>
      </div>
    </div>
  )
}

const SignalChains = ({ newSignalChain, load, unload, loading, signalChains, otherProfile }) => {

  useEffect(() => {
    load()
    return unload
  }, [])
  
  return (
    <div className="signal-chains-container">
      <div className="profile-header-bar">
        <div className="profile-header">
          <h2>{otherProfile ? '' : 'My '}Signal Chains</h2>
          { !loading && (
            <span className="count-badge">{signalChains.length}</span>
          )}
        </div>
        <div className="button button-secondary" onClick={newSignalChain}>+ Create New Signal Chain</div>
      </div>
      <div className={`my-signal-chains profile-content ${!loading && signalChains.length == 0 ? 'empty' : ''}`}>
        { loading && (
          <div className="loader-container" style={ { height: '200px' } }>
            <div className="loader"/>
          </div>
        )}
        { !loading && signalChains.length == 0 && (
          <div className="empty-content">
            <div className="icon"><FolderOffIcon/></div>
            <div>
              <h4>No Signal Chains Yet!</h4>
              { !otherProfile && <p>Now create your first signal chain</p> }
            </div>
            { !otherProfile && <div className="button button-primary" onClick={newSignalChain}>+ Create My First Signal Chain</div> }
          </div>
        )}
        { !loading && signalChains.length > 0 && (
          <div className="signal-chains">
            { signalChains.map((sc, i) => {
            console.log(sc)
              return (
                <SignalChain signalChain={sc} key={i} otherProfile={otherProfile}/>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.signalChain.signalChainsLoading,
    signalChains: state.signalChain.signalChains
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    newSignalChain: () => { dispatch(reset('new_signal_chain')); dispatch(openModal('NEW_SIGNAL_CHAIN')) },
    load: () => { dispatch(loadSignalChains(ownProps.userId)) },
    unload: () => { dispatch(unloadSignalChains()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignalChains)