import React, { useState, useEffect, useRef, useCallback } from "react"
import ExpandIcon from '../images/expand_more.svg'

const selectField = ({ input, label, options, placeholder, formRef, meta: { touched, error, active } }) => {
  
  const [open, setOpen] = useState(false)
  const menuRef = useRef()
  const selectRef = useRef()
  
  const menuCloseListener = useCallback((e) => {
    let menuBox = menuRef.current.getBoundingClientRect()
    if(menuRef.current && !menuRef.current.contains(e.target) && !selectRef.current.contains(e.target)) {
      closeMenu()
    }
  }, [])
  
  const openMenu = () => {
    setOpen(true)
    window.addEventListener("mousedown", menuCloseListener, false)
    window.addEventListener("click", menuCloseListener, false)
  }
  
  const closeMenu = () => {
    setOpen(false)
    window.removeEventListener("mousedown", menuCloseListener, false)
    window.removeEventListener("click", menuCloseListener, false)
  }
  
  const selectItem = (value) => {
    input.onChange(value)
    closeMenu()
  }
  
  const textId = `${input.name.replace(/\[|\]/g, '_')}-select-input`
  
  return (
  <div className="form-group">
    <label>{label}</label>
    <select {...input} className="select-input">
      { options.map((op) => {
        return (
          <option key={op.value} value={op.value}>{op.label}</option>
        )
      })}
    </select>
    <div className="select-field">
      <div className={open ? "select-text-input active" : "select-text-input"} id={textId} ref={selectRef} onClick={ () => { if(open) { closeMenu() } else { openMenu() }} }>
        {input.value == undefined || input.value.length == 0 ? placeholder : options.filter((op) => op.value == input.value)[0].label }
        <div className="caret"><ExpandIcon/></div>
      </div>
      <div className={open ? 'select-menu open' : 'select-menu'} ref={menuRef}>
        <div className="select-menu-item" value="" onClick={ () => selectItem("") }>None</div>
        { options.map((op) => {
          return (
            <div key={op.value} className={ op.value == input.value ? "select-menu-item selected" : "select-menu-item" } onClick={ () => selectItem(op.value) }>{op.label}</div>
          )
        })}
      </div>
    </div>
    {touched && error && !active && <span className="error">{error}</span>}
  </div>
  )
}


export { selectField }