import React from "react"

const textAreaField = ({ input, label, placeholder, type, meta: { touched, error, active } }) => (
  <div className="form-group">
    <label>{label}</label>
    <textarea {...input} placeholder={placeholder || label}/>
    {touched && error && !active && <span className="error">{error}</span>}
  </div>
)

export { textAreaField }