import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, FieldArray, reduxForm, change, touch, initialize } from 'redux-form'
import { required } from 'redux-form-validators'
import { createStructuredSelector } from 'reselect'
import { textField } from '../../fieldRenderers/textField'
import { dateField } from '../../fieldRenderers/dateField'
import { moneyField } from '../../fieldRenderers/moneyField'
import { textAreaField } from '../../fieldRenderers/textAreaField'
import { imageUploadField } from '../../fieldRenderers/imageUploadField'
import { nameValueFieldArray } from '../../fieldRenderers/nameValueFieldArray'
import { saveGearDetails } from '../../actions/gearActions'

import ComputerIcon from '../../images/computer.svg'
import OverviewKeyIcon from '../../images/overview_key.svg'


let DetailsForm = ({ handleSubmit, usersGear }) => {
  const reduxDispatch = useDispatch()

  // this is here to initialize the form from the existing signal chain  
  useEffect(() => {
    if(usersGear) {
      console.log("usersGear values", usersGear)
      reduxDispatch(initialize('gear_details', { gear_details: usersGear }))
      Object.keys(usersGear).forEach(fieldName => {
        reduxDispatch(touch('gear_details', fieldName))
      });
    }
  }, [usersGear])
  
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <div className="modal-form-scroll">
        <Field label="Serial Number" name='gear_details[serial_number]' component={textField} />
        <Field label="Date Purchased" name='gear_details[purchased_date]' component={dateField} />
        <Field label="Purchased From" name='gear_details[purchased_from]' component={textField} />
        <Field label="Purchase Price" name='gear_details[price]' component={moneyField} />
        <FieldArray label="Additional Details" addLabel="Add New Detail" name='gear_details[additional_data]' component={nameValueFieldArray} />
        <Field label="Photos" name='gear_details[images]' component={imageUploadField} multiple={true} />
        <Field label="Notes" name='gear_details[notes]' component={textAreaField} />
        <Field label="Pickups Used" name='gear_details[pickups_used]' component={textField} />
        <Field label="Strings Used" name='gear_details[strings_used]' component={textField} />
        <Field label="Last String Change" name='gear_details[last_string_change]' component={dateField} />
        <Field label="String Change Reminder" name='gear_details[string_change_reminder]' component={dateField} />
      </div>
      <div className="form-group submit-button" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' value="Save Gear Details"/>
      </div>
    </form>
  )
}

DetailsForm = reduxForm({
  form: 'gear_details'
})(DetailsForm)

DetailsForm = connect((state, ownProps) => {
  return {
    usersGear: state.gear.activeUsersGear || {}
  }
})(DetailsForm)

const GearDetailsModal = ({ submit, errors, usersGear }) => {
  return (
    <div className="gear-details-modal form-modal">
      <div className="modal-body">
        <div className="modal-header">
          <h2>{usersGear.gear.name}</h2>
          { !errors.length && 
            (
              <p>Edit the details of your gear below</p>
            )
          }
          { errors && errors.map((error, i) => {
            return (
              <p key={ i }>{error}</p>
            )
          })}
        </div>
        <DetailsForm onSubmit={ submit }/>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  errors: state => [],
  usersGear: state => state.gear.activeUsersGear
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(saveGearDetails(params)),
  }
}

export default connect(selector, mapDispatchToProps)(GearDetailsModal);