const initialState = {
  formName: undefined,
  fieldName: undefined,
  multiple: false,
  gearType: undefined,
  useForm: true,
  useCallback: false,
  callback: undefined
}


function gearSelectionReducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_GEAR_SELECTION_FORM_PARAMS':
      return {...state, ...action.data }
    default:
      return state
  }
}

export default gearSelectionReducer
