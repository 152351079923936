import React, { useState, useEffect, useCallback, useRef } from 'react'

import PlayIcon from '../../images/play_circle.svg'
import PauseIcon from '../../images/pause_circle.svg'

const AudioClipPlayer = ({clip}) => {
  const player = useRef()
  const [playState, setPlayState] = useState('stopped')
  
  const startPlayback = () => {
    player.current.play()
    setPlayState('playing')
  }
  
  const pausePlayback = () => {
    player.current.pause()
    setPlayState('paused')
  }
  
  useEffect(() => {
    player.current.addEventListener("ended", () => {
      setPlayState('stopped')
    })
  }, [player])
  
  return (
    <div className="audio-clip">
      <audio ref={player}>
        <source src={clip.url} type="audio/wav" />
        <source src={clip.url} type="audio/ogg" />
        <source src={clip.url} type="audio/mp3" />
      </audio>
      { (playState == 'paused' || playState == 'stopped') ? (
        <PlayIcon onClick={startPlayback} className="icon"/>
      ) : (
        <PauseIcon onClick={pausePlayback} className="icon"/>
      )}
      <span className="name">{clip.name}</span>
    </div>
  )
}

export { AudioClipPlayer }