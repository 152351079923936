import React from "react"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { submitOnboardingStep1 } from '../../actions/userActions'
import { radioPanel } from '../../fieldRenderers/radioPanel'
import { transformKeysCamelToSnake } from '../../utils/documentUtils'

import InfoTooltip from '../controls/InfoTooltip'
import GuitarIcon from '../../images/Icon_Guitar.svg'
import KeyboardIcon from '../../images/Icon_Keyboard.svg'
import BassIcon from '../../images/Icon_Bass.svg'

let InstrumentForm = ({ handleSubmit, initialValues }) => {
  console.log("initialValues", initialValues)
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <div className="radio-panel-group">
        <Field label="Guitar" name='user[primary_instrument]' id="user_instrument_guitar" component={radioPanel} type='radio' value="guitar" image={GuitarIcon} />
        <Field label="Keyboard" name='user[primary_instrument]' id="user_instrument_keyboard" component={radioPanel} type='radio' value="keyboard" image={KeyboardIcon} />
        <Field label="Bass" name='user[primary_instrument]' id="user_instrument_bass" component={radioPanel} type='radio' value="bass" image={ BassIcon} />
      </div>
      <div className="form-group">
        <input className="continue" name='submit' type='submit' value="Continue"/>
      </div>
    </form>
  )
}

InstrumentForm = reduxForm({
  form: 'onboarding_primary_instrument_step',
  enableReinitialize: true
})(InstrumentForm)

InstrumentForm = connect(state => {
  console.log('in instrument form, state:', state)
  return {
    initialValues: {
      user: {
        primary_instrument: (state.user && state.user.primaryInstrument) || 'guitar'
      }
    }
  }
})(InstrumentForm)

const OnboardingStep1 = ({ submit }) => {
  return ( 
    <div className="onboarding-modal">
      <div className="onboarding-modal-header">
        <h2>What is your primary instrument?</h2>
        <p>Why do we ask you this?<InfoTooltip>Lorem ipsum dolor sit, consecte tur adipislla cond malesu porem ipsum dolor sit, consectetur adipislla cond lesuada auctor.</InfoTooltip></p>
      </div>
      <InstrumentForm onSubmit={ submit }/>
    </div>
  )
}

const selector = createStructuredSelector({})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitOnboardingStep1(params)),
  }
}

export default connect(selector, mapDispatchToProps)(OnboardingStep1);