import { openModal, closeModal } from './modalActions'
import { reset, change } from 'redux-form'
import { waitFor } from '../utils/promises'

const openGearSelectionModalFromForm = (formName, fieldName, multiple, gearType) => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_GEAR_SELECTION_FORM_PARAMS', data: {
      formName: formName,
      fieldName: fieldName,
      multiple: multiple,
      gearType: gearType,
      useForm: true,
      useCallback: false,
      callback: () => {}
    }})
    waitFor(100).then(() => {
      dispatch(reset('generic_gear_selection'))
      dispatch(openModal('GENERIC_GEAR_SELECTION'))
    })
  }
}

const openGearSelectionModalWithCallback = (multiple, gearType, callback) => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_GEAR_SELECTION_FORM_PARAMS', data: {
      formName: undefined,
      fieldName: undefined,
      multiple: multiple,
      gearType: gearType,
      useForm: false,
      useCallback: true,
      callback: callback
    }})
    waitFor(100).then(() => {
      dispatch(reset('generic_gear_selection'))
      dispatch(openModal('GENERIC_GEAR_SELECTION'))
    })
  }
}

const submitGearSelectionModal = (valuesFromForm) => {
  return (dispatch, getState) => {
    console.log("submitting gear selection modal", valuesFromForm)
    const gearSelectionState = getState().gearSelection
    if(valuesFromForm.generic_gear_selection && gearSelectionState.useForm) {
      dispatch(change(gearSelectionState.formName, gearSelectionState.fieldName, valuesFromForm.generic_gear_selection.result))
    }
    else if(valuesFromForm.generic_gear_selection && gearSelectionState.useCallback) {
      gearSelectionState.callback(valuesFromForm.generic_gear_selection.result)
    }
    dispatch(closeModal())
  }
}

export { openGearSelectionModalFromForm, openGearSelectionModalWithCallback, submitGearSelectionModal }