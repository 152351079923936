import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { submitChangePassword } from '../../../actions/userActions'
import { passwordField } from '../../../fieldRenderers/passwordField'
import { required } from 'redux-form-validators'

let PasswordForm = ({ handleSubmit, errors }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit } style={{gap: '24px'}}>
      <Field label="Existing Password" name='user[existing_password]' component={passwordField} type='password' validate={[required()]}/>
      <Field label="New Password" name='user[password]' component={passwordField} type='password' validate={[required()]}/>
      <Field label="Confirm New Password" name='user[password_confirmation]' component={passwordField} type='password' validate={[required()]}/>
      { errors.map((error, i) => {
        return <p className="form-error" key={i}>{error}</p>
      })}
      <div className="form-group" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' value="Update Password"/>
      </div>
    </form>
  )
}

PasswordForm = reduxForm({
  form: 'change_password',
  enableReinitialize: true
})(PasswordForm)

PasswordForm = connect((state) => {
  return {}
})(PasswordForm)

const ChangePassword = ({ submit, passwordErrors }) => {
  return (
    <PasswordForm onSubmit={submit} errors={passwordErrors}/>
  )
}

const selector = createStructuredSelector({
  passwordErrors: (state) => state.user.passwordErrors
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitChangePassword(params)),
  }
}

export default connect(selector, mapDispatchToProps)(ChangePassword)