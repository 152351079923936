import React, { useState } from 'react'
import TooltipIcon from '../../images/help-icon.svg'

const InfoTooltip = ({children}) => {
    let [timeoutHandle, setTimeoutHandle] = useState(false)
    let [open, setOpen] = useState(false)
    
    const onMouseEnter = () => {
        setTimeoutHandle(setTimeout(() => setOpen(true), 500))
    }
    
    const onMouseLeave = () => {
        setOpen(false)
        clearTimeout(timeoutHandle)
    }
    
    return (
        <span className="info-tooltip">
            <span className="info-icon" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ><TooltipIcon/></span>
            <span className={`tooltip ${open ? "open" : ""}`}>{children}</span>
        </span>
    )
}

export default InfoTooltip