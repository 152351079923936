import React, { useState, useEffect, useCallback, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { ReactMic } from 'react-mic'
import { v4 } from 'uuid'

import RecordIcon from '../images/record.svg'
import UploadIcon from '../images/upload.svg'
import RemoveIcon from '../images/trash.svg'
import PlayIcon from '../images/play_circle.svg'
import StopIcon from '../images/stop_circle.svg'
import PauseIcon from '../images/pause_circle.svg'

const AudioClip = ({clip, removeClip}) => {
  const player = useRef()
  const [playState, setPlayState] = useState('stopped')
  
  const startPlayback = () => {
    player.current.play()
    setPlayState('playing')
  }
  
  const pausePlayback = () => {
    player.current.pause()
    setPlayState('paused')
  }
  
  useEffect(() => {
    player.current.addEventListener("ended", () => {
      setPlayState('stopped')
    })
  }, [player])
  
  return (
    <div className="audio-clip">
      <audio ref={player}>
        <source src={clip.url} type="audio/wav" />
        <source src={clip.url} type="audio/ogg" />
        <source src={clip.url} type="audio/mp3" />
      </audio>
      { (playState == 'paused' || playState == 'stopped') ? (
        <PlayIcon onClick={startPlayback} className="icon"/>
      ) : (
        <PauseIcon onClick={pausePlayback} className="icon"/>
      )}
      <span className="name">{clip.name}</span>
      <div className="remove" onClick={() => removeClip(clip.id)}><RemoveIcon className="icon"/></div>
    </div>
  )
}

const multiAudioClipUploadField = ({ input, label, meta: { touched, error, active } }) => {
  const [clips, setClips] = useState([])
  const [isRecording, setIsRecording] = useState(false)

  useEffect(() => {
    setClips(input.value)
  }, [input.value])

  const onDrop = useCallback((acceptedFiles) => {
    let files = []
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onloadend = () => {
        setClips((prevClips) => {
          return [...prevClips, {
          url: reader.result,
          file: file,
          name: reader.fileName,
          id: v4()
        }]})
      }
      reader.readAsDataURL(file)
    })
  }, [])
  
  const removeClip = (id) => {
    console.log("removing clip", clips, id)
    const newClips = clips.filter((i) => i.id !== id)
    console.log("new clips", newClips)
    setClips(newClips)
  }

  const handleRecordStart = () => {
    setIsRecording(true)
  }

  const handleRecordStop = () => {
    setIsRecording(false)
  }

  const handleStoringClip = (recordedBlob) => {
    // Handle the recorded audio blob (recordedBlob.blob)
    setClips((oldClips) => {
      let sampleNameIndex = 1
      while(oldClips.filter((c) => c.name == `Audio Clip ${sampleNameIndex}`).length > 0) { sampleNameIndex += 1 }
      const newName = `Audio Clip ${sampleNameIndex}`
      let blob = recordedBlob.blob
      return [...oldClips, {
        url: URL.createObjectURL(recordedBlob.blob),
        file: recordedBlob.blob,
        name: newName,
        id: v4()
      }]
    })
  }

  
  useEffect(() => {
    input.onChange(clips)
  }, [clips])
  
  return (
    <div className='form-group'>
      <div className="multi-audio-clip-header">
        <label>{label}</label>

        <ReactMic
          record={isRecording}
          className="sound-wave"
          onStop={handleStoringClip}
        />
        <div className="controls">
          { isRecording ? (
            <div onClick={handleRecordStop} className="control"><StopIcon/>Stop</div>
          ) : (
            <div onClick={handleRecordStart} className="control"><RecordIcon/>Record</div>
          )}
          
          <Dropzone onDrop={onDrop} accept={{"audio/*": ['.mp3', '.wav', '.ogg']}} multiple>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="audio-clip-dropzone control"><UploadIcon/>Upload</div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
      <div className="multi-audio-clip-upload">
        { clips.map((clip, i) => {
          return <AudioClip clip={clip} removeClip={removeClip} key={clip.id}/>
        })}
      </div>
    </div>
  )
}

export { multiAudioClipUploadField }