import React from 'react'
import axios from 'axios'

const fetchExploreUsers = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: 'FETCHING_EXPLORE_USERS', params: params})
    axios.get('/api/v2/explore/users.json', params)
      .then((response) => {
        console.log('explore users', response.data)
        dispatch({ type: 'SET_EXPLORE_USERS', users: response.data })
      })
  }
}

const fetchExploreSignalChains = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: 'FETCHING_EXPLORE_SIGNAL_CHAINS', params: params})
    axios.get('/api/v2/explore/signal_chains.json', params)
      .then((response) => {
        console.log('explore signal chains', response.data)
        dispatch({ type: 'SET_EXPLORE_SIGNAL_CHAINS', signalChains: response.data })
      })
  }
}


export {
  fetchExploreUsers,
  fetchExploreSignalChains
}