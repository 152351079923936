import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Route, Routes, Link, NavLink, useParams } from 'react-router-dom'
import { loadOtherUser, unloadOtherUser } from '../../actions/userActions'
import { followUser, unfollowUser } from '../../actions/followingActions'
import SignalChains from './profilePages/SignalChains'
import MyGear from './profilePages/MyGear'
import Followers from './profilePages/Followers'
import Following from './profilePages/Following'

import defaultProfileImage from '../../images/default-profile-image.jpg'
import EditIcon from '../../images/edit.svg'

const ProfilePage = ({ user, currentUser, myProfile, otherUserLoading }) => {
  const { userId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    if(!myProfile && userId) {
      dispatch(loadOtherUser(userId))
    }
    return () => {
      unloadOtherUser()
    }
  }, [userId, myProfile])

  const memberSinceDate = (date) => {
    return `${date.toLocaleString('default', { month: 'short' })} ${date.getYear()+1900}`
  }
  
  const follow = () => {
    dispatch(followUser(user.id))
  }

  const unfollow = () => {
    dispatch(unfollowUser(user.id))
  }
  
  return (
    <div className="profile-page-container">
      { (myProfile || !otherUserLoading) ? (
      <div>
        <div className="profile-bio-container">
          <div className="backdrop"></div>
          <div className="profile-bio">
            <div className="profile-name-image-main-container">
              <div className="profile-name-image-container">
                <div className="profile-name-image">
                  <div className="profile-image">
                    <img className="profile" src={ (user.avatar && user.avatar[0].url) || defaultProfileImage }/>
                  </div>
                  <div className="name-and-details">
                    <h3>{user.username}</h3>
                    <p>{user.primaryInstrument}ist</p>
                    <p>Member since {memberSinceDate(user.createdAt)}</p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="followings-container">
                  <div className="followers">
                    <h3>{user.followerCount}</h3>
                    <p>Followers</p>
                  </div>
                  <div className="following">
                    <h3>{user.followingIds.length}</h3>       
                    <p>Following</p>      
                  </div>      
                </div>      
              </div>      
            </div>      
            <div className="profile-details-container">      
              <div className="profile-details">      
                <div className="bio">      
                  <h4>{myProfile ? 'My ' : ''}Bio</h4>      
                  <p>{user.bio || 'No bio to share'}</p>      
                </div>      
                <div className="genres">      
                  <h4>Music Preferences</h4>             
                  { (user.genres.length == 0) && <p>No preferences to show</p> }
                  { (user.genres.length > 0) && 
                    (
                      <div className="pill-list">
                        { user.genres.map((g, i) => 
                          (
                            <span className="pill" key={i}>{g.name}</span>
                          ))
                        }
                      </div>
                    )
                  }
                </div>
                <div className="daws">      
                  <h4>DAWs Used</h4>             
                  { (user.daws.length == 0) && <p>Doesn't use DAWs</p> }
                  { (user.daws.length > 0) && 
                    (
                      <div className="pill-list">
                        { user.daws.map((d, i) => 
                          (
                            <span className="pill" key={i}>{d.name}</span>
                          ))
                        }
                      </div>
                    )
                  }
                </div>
                <div className="favorite">
                  <h4>Favorite Instrument</h4>
                  <p>{(user.favoriteInstrument && user.favoriteInstrument.name) || 'None Selected'}</p>
                </div>
                <div className="favorite">
                  <h4>Favorite Pedal</h4>
                  <p>{(user.favoritePedal&& user.favoritePedal.name) || 'None Selected'}</p>
                </div>
                <div className="favorite">
                  <h4>Favorite Amp</h4>
                  <p>{(user.favoriteAmp && user.favoriteAmp.name) || 'None Selected'}</p>
                </div>
              </div>
              { myProfile ? (
                <Link to="/settings/personal" className="button button-secondary settings-button"><EditIcon/>Edit Profile</Link>
              ) : ( currentUser.followingIds.includes(user.id) ? (
                <div className="button button-tertiary" onClick={unfollow}>Unfollow</div>
              ) : (  
                <div className="button button-secondary" onClick={follow}>Follow</div>
              ))}
            </div>
          </div>
        </div>
        { myProfile ? (
          <div className="profile-content-container">
            <div className="profile-menu pill-list">
              <NavLink to="/profile/gear" className="pill blue-pill">My Gear</NavLink>
              <NavLink to="/profile/signal_chains" className="pill blue-pill">My Signal Chains</NavLink>
              <NavLink to="/profile/gigs" className="pill blue-pill">My Gigs</NavLink>
              <NavLink to="/profile/favorites" className="pill blue-pill">Favorites</NavLink>
              <NavLink to="/profile/followers" className="pill blue-pill">Followers</NavLink>
              <NavLink to="/profile/following" className="pill blue-pill">Following</NavLink>
            </div>
            <Routes>
              <Route path="/signal_chains" element={ <SignalChains otherProfile={false} userId={currentUser.id}/> }/>
              <Route path="/gear" element={ <MyGear otherProfile={false} userId={currentUser.id}/> }/>
              <Route path="/followers" element={ <Followers otherProfile={false} userId={currentUser.id}/> }/>
              <Route path="/following" element={ <Following otherProfile={false} userId={currentUser.id}/> }/>
            </Routes>
          </div>
        ) : (
          <div className="profile-content-container">
            <div className="profile-menu pill-list">
              <NavLink to={`/users/${user.id}/profile/gear`} className="pill blue-pill">Gear</NavLink>
              <NavLink to={`/users/${user.id}/profile/signal_chains`} className="pill blue-pill">Signal Chains</NavLink>
              <NavLink to={`/users/${user.id}/profile/gigs`} className="pill blue-pill">Gigs</NavLink>
              <NavLink to={`/users/${user.id}/profile/favorites`} className="pill blue-pill">Favorites</NavLink>
              <NavLink to={`/users/${user.id}/profile/followers`} className="pill blue-pill">Followers</NavLink>
              <NavLink to={`/users/${user.id}/profile/following`} className="pill blue-pill">Following</NavLink>
            </div>
            <Routes>
              <Route path="/signal_chains" element={ <SignalChains otherProfile={true} userId={user.id}/> }/>
              <Route path="/gear" element={ <MyGear otherProfile={true} userId={user.id}/> }/>
              <Route path="/followers" element={ <Followers otherProfile={true} userId={user.id}/> }/>
              <Route path="/following" element={ <Following otherProfile={true} userId={user.id}/> }/>
            </Routes>
          </div>
        )}
      </div>
      ) : (
        <div className="profile-loading"/>
      )}
    </div>
  )
}

const selector = (state, ownProps) => {
  console.log("profile data", ownProps, state)
  return {
    user: ownProps.myProfile ? state.user.currentUser : state.user.otherUser,
    currentUser: state.user.currentUser,
    otherUserLoading: state.user.otherUserLoading
  }
}


export default connect(selector)(ProfilePage)