import React, { useState, useEffect, useRef, useCallback } from 'react'

const PopupMenu = ({ options, triggeringRef, className, closeCallback }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef()
  const menuOpenCallbackRef = useRef(null)

  const menuCloseListener = useCallback((e) => {
    if(menuRef.current && !menuRef.current.contains(e.target) && !triggeringRef.current.contains(e.target)) {
      setMenuOpen(false)
      closeCallback && closeCallback()
    }
  }, [])
  
  const menuOpenListener = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen])
  
  useEffect(() => {
    if(menuOpenCallbackRef) {
      triggeringRef.current.removeEventListener("click", menuOpenCallbackRef.current, false)
    }
    if(triggeringRef.current) {
      triggeringRef.current.addEventListener("click", menuOpenListener, false)
      menuOpenCallbackRef.current = menuOpenListener
    }
    return () => {
      if(triggeringRef.current) {
        triggeringRef.current.removeEventListener("click", menuOpenCallbackRef.current, false)
      }
    }
  }, [triggeringRef, menuOpenListener])

  useEffect(() => {
    if(menuOpen) {
      window.addEventListener("mousedown", menuCloseListener, false)
      window.addEventListener("click", menuCloseListener, false)
    }
    else {
      window.removeEventListener("mousedown", menuCloseListener, false)
      window.removeEventListener("click", menuCloseListener, false)
    }
  }, [menuOpen])

  return (
    <div className={`popup-menu ${className} ${menuOpen ? 'visible open' : ''}`} ref={menuRef}>
      { options.map((option, i) => {
        return (
          <a key={i} onClick={ () => { setMenuOpen(false); closeCallback && closeCallback(); option.onClick() }}>{ option.icon && React.createElement(option.icon) }<span>{option.label}</span></a>
        )
      })}
    </div>
  )
}

export default PopupMenu