const initialState = {
  followersLoading: false,
  followingsLoading: false,
  followers: [],
  followings: []
}

const followingReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_FOLLOWERS_LOADING': {
      return {
        ...state,
        followersLoading: action.loading
      }
    }
    case 'SET_FOLLOWINGS_LOADING': {
      return {
        ...state,
        followingsLoading: action.loading
      }
    }
    case 'SET_FOLLOWERS': {
      return {
        ...state,
        followers: action.followers
      }
    }
    case 'SET_FOLLOWINGS': {
      return {
        ...state,
        followings: action.followings
      }
    }
    default: {
      return state
    }
  }
}

export default followingReducer