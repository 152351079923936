import React from "react"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { submitOnboardingStep3 } from '../../actions/userActions'
import { transitionModalLeft, transitionModalRight } from '../../actions/modalActions'
import { searchableAjaxMultiSelect } from '../../fieldRenderers/searchableAjaxMultiSelect'
import { transformKeysCamelToSnake } from '../../utils/documentUtils'

import InfoTooltip from '../controls/InfoTooltip'
import ArrowBack from '../../images/arrow_back.svg'

let OnboardingForm = ({ handleSubmit, skip }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <Field name="user[daws]" component={searchableAjaxMultiSelect} dataUrl={'/api/v2/daws.json'}/>
      <div className="form-group">
        <input className="continue" name='submit' type='submit' value="Continue"/>
      </div>
      <div className="form-group">
        <input className="skip" name='submit' type='submit' value="Skip" onClick={ skip }/>
      </div>
    </form>
  )
}

OnboardingForm = reduxForm({
  form: 'onboarding_daws_step',
  enableReinitialize: true
})(OnboardingForm)

OnboardingForm = connect((state) => { return { initialValues: { user: transformKeysCamelToSnake(state.user) } } })(OnboardingForm)

const OnboardingStep3 = ({ submit, skip, back }) => {
  return ( 
    <div className="onboarding-modal">
      <div className="onboarding-modal-header">
        <div className="back-button" onClick={ back }><ArrowBack/></div>
        <h2>What DAWs do you use?</h2>
        <p>Why do we ask you this?<InfoTooltip>Lorem ipsum dolor sit, consecte tur adipislla cond malesu porem ipsum dolor sit, consectetur adipislla cond lesuada auctor.</InfoTooltip></p>
      </div>
      <OnboardingForm onSubmit={ submit } skip={ skip }/>
    </div>
  )
}

const selector = createStructuredSelector({
  loginError: state => state.user.loginError
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitOnboardingStep3(params)),
    back: () => dispatch(transitionModalLeft('ONBOARDINGSTEP2')),
    skip: () => dispatch(transitionModalRight('WELCOME'))
  }
}

export default connect(selector, mapDispatchToProps)(OnboardingStep3);