import React from "react"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required, email } from 'redux-form-validators'
import { alphanumeric, nameCharacters } from '../../fieldRenderers/validators'
import { createStructuredSelector } from 'reselect'
import { openModal } from '../../actions/modalActions'
import { signup } from '../../actions/userActions'
import { textField } from '../../fieldRenderers/textField'
import { passwordField } from '../../fieldRenderers/passwordField'

let SignupForm = ({ handleSubmit, error }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <div className="flex-row">
        <Field label="First Name" name='user[first_name]' component={textField} type='text' validate={[required(), alphanumeric]}/>
        <Field label="Last Name" name='user[last_name]' component={textField} type='text'/>
      </div>
      <Field label="Username" name='user[username]' component={textField} type='text' validate={[required(), alphanumeric]}/>
      <Field label="Email" name='user[email]' component={textField} type='email' validate={[required(), email()]}/>
      <Field label="Password" name='user[password]' component={passwordField} type='password' validate={[required()]}/>
      <div className="form-group" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' text="Create Account"/>
      </div>
    </form>
  )
}

SignupForm = reduxForm({
  form: 'signup'
})(SignupForm)

const SignupModal = ({ login, submit, signupErrors }) => {
  return (
    <div className="signup-modal">
      <div className="signup-modal-image"></div>
      <div className="bolt"></div>
      <div className="signup-modal-body">
        <div className="signup-modal-header">
          <h2>Create an account</h2>
          { !signupErrors && 
            (
              <p>Fill out the form below to create your Rigfish account.</p>
            )
          }
          { signupErrors && 
            (
              <p className="error">{ signupErrors }</p>
            )
          }
        </div>
        <SignupForm onSubmit={ submit }/>
        <hr/>
        <div className="omniauth-buttons">
          <div className="omniauth-facebook"><span className="icon"/>Continue with Facebook</div>
          <div className="omniauth-google"><span className="icon"/>Continue with Google</div>
        </div>
        <p className="signup-link">Have an account? <a onClick={ login }><strong>Login</strong></a></p>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  signupForm: state => state.form.signup
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(signup(params)),
    login: () => dispatch(openModal('LOGIN'))
  }
}

export default connect(selector, mapDispatchToProps)(SignupModal);