import React, {useState, useEffect, useRef} from "react"
import { useDispatch } from 'react-redux'
import axios from 'axios'
import CheckIcon from '../images/check.svg'
import AddIcon from '../images/add.svg'
import SearchIcon from '../images/search.svg'
import PencilIcon from '../images/edit.svg'

// get all possible options from db
// get search results from db
// use input.value instead of how I was doing it before

const searchableAjaxMultiSelect = ({ input, label, id, dataUrl, useEditButton, formName, meta, meta: { touched, error, active, pristine } }) => {
  let [options, setOptions] = useState([])
  let [searchTerm, setSearchTerm] = useState()
  let [editing, setEditing] = useState(!useEditButton)
  
  useEffect(() => {
    setEditing(!useEditButton || active)
  }, [active])
  
  useEffect(() => {
    axios.get(dataUrl, { params: { query: searchTerm, results: 10 } })
      .then((result) => {
        let newOptions = result.data.filter((option) => !(input.value || []).includes(option.id))
        setOptions(newOptions)
        })
  }, [searchTerm])
  
  const selectOption = (id) => {
    let newValue = [...input.value, options.filter((op) => op.id == id)[0]]
    input.onChange(newValue)
  }
  
  const deselectOption = (id) => {
    let newValue = input.value.filter((v) => v.id !== id)
    input.onChange(newValue)
  }
  
  return (
    <div className="form-group ajax-multi-select">
      { label && (
        <label>{label}</label>
      )}
      { editing && (
        <div className="search-container">
          <input className="search" type="text" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search"/>
          <span className="search-icon"><SearchIcon/></span>
        </div>
      )}
      <select multiple name={input.name} id={id} value={input.value || []} style={{display: 'none'}} readOnly>
        { (input.value || []).map((op) => (
          <option key={op.id} value={op.id}>{op.name}</option>
          ))
        }
        { options.filter((op) => !(input.value || []).map((v) => v.id).includes(op.id)).map((op) => (
          <option key={op.id} value={op.id}>{op.name}</option>
          ))
        }
      </select>
      { editing ? (
        <div className="options">
          { (input.value || []).map((option) => (
            <span key={option.id} className="selected" onClick={() => deselectOption(option.id)}><CheckIcon/>{option.name}</span>
            ))
          }
          { options.filter((op) => !(input.value || []).map((v) => v.id).includes(op.id)).map((option) => (
            <span key={option.id} onClick={() => selectOption(option.id)}><AddIcon/>{option.name}</span>
            ))
          }
          { options.length == 0 && (
            <span className="no-results">No results</span>
            )
          }
        </div>
      ) : (
        <div className="values pill-list">
          { (input.value || []).map((option) => (
            <span key={option.id} className="pill">{option.name}</span>
            ))
          }
          { input.value.length == 0 && (
            <span className="pill">None</span>
          )}
          <span className="blue-pill" onClick={() => input.onFocus()}><PencilIcon/>Edit</span>
        </div>
      )}
      {touched && error && !active && <span className="error">{error}</span>}
    </div>
  )
}

export { searchableAjaxMultiSelect }