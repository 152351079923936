import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import gearSelection from '../../fieldRenderers/gearSelection'
import { submitGearSelectionModal } from '../../actions/gearSelectionActions'

// from external form:
// form button clicked
// dispatches action to open this modal and sets the form name, field name, multiple, instrument type
// when this form is submitted, dispatch an action that will update the form and close the modal

let GearForm = ({ handleSubmit, gearType, multiple }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <Field name="generic_gear_selection[result]" component={gearSelection} gearType={gearType} multiple={multiple}/>
      <div className="buttons">
        <div className="form-group">
          <input className="select" name='submit' type='submit' value="Select"/>
        </div>
      </div>
    </form>
  )
}

GearForm = reduxForm({
  form: 'generic_gear_selection'
})(GearForm)

GearForm = connect((state, ownProps) => ({
}))(GearForm)

const GenericGearSelectionModal = ({ back, skip, submit, gearType, gearId, connectionId, multiple, modalKey }) => {
  const headings = {
    'instrument': 'Instrument',
    'pedal': 'Pedal',
    'amp': 'Amp',
    'other': 'Miscellaneous Item'
  }

  return (
    <div className={ multiple ? "gear-selection-modal multiple" : "gear-selection-modal" }>
      <div className="modal-header">
        <h2>Select {headings[gearType].charAt(0).toUpperCase() + headings[gearType].slice(1)}{ multiple ? "s" : "" }</h2>
      </div>
      <GearForm key={modalKey} onSubmit={ submit } multiple={ multiple } gearType={ gearType } gearId={ gearId } connectionId={ connectionId } multiple={ multiple }/>
    </div>
  )
}

const selector = createStructuredSelector({
  gearType: state => state.gearSelection.gearType,
  multiple: state => state.gearSelection.multiple
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitGearSelectionModal(params)),
  }
}

export default connect(selector, mapDispatchToProps)(GenericGearSelectionModal)