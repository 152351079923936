import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Route, Routes, NavLink, BrowserRouter } from 'react-router-dom'

import UserSettings from './settingsPages/UserSettings'
import ChangePassword from './settingsPages/ChangePassword'

import defaultProfileImage from '../../images/default-profile-image.jpg'
import AddAPhotoIcon from '../../images/add_a_photo.svg'

const AccountSettingsPage = ({  }) => {
  return (
    <div className="account-settings-page-container">
      <h2 className="page-title">Account Settings</h2>
      <div className="content-wrapper">
        <div className="menu-container">
          <NavLink to="/settings/personal" className="menu-item">Personal Information</NavLink>
          <NavLink to="/settings/change_password" className="menu-item">Change Password</NavLink>
          <NavLink to="/settings/notifications" className="menu-item">Notifications</NavLink>
          <NavLink to="/settings/privacy" className="menu-item">Privacy</NavLink>
        </div>
        <div className="content-container">
          <Routes>
            <Route path="/personal" element={ <UserSettings/> }/>
            <Route path="/change_password" element={ <ChangePassword/> }/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
})


export default connect(selector)(AccountSettingsPage)