import axios from 'axios'
import { reloadUser } from './userActions'

const fetchFollowers = (otherProfile=false) => {
  return (dispatch, getState) => {
    const url = otherProfile ? `/api/v2/users/${getState().user.otherUser.id}/followers.json` : '/api/v2/followers.json'
    axios.get(url)
      .then((response) => {
        dispatch({type: 'SET_FOLLOWERS', followers: response.data})
        dispatch({type: 'SET_FOLLOWERS_LOADING', loading: false})
      })
      .catch(() => {
        dispatch({type: 'SET_FOLLOWERS_LOADING', loading: false})
      })
  }
}

const loadFollowers = (otherProfile=false) => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_FOLLOWERS_LOADING', loading: true})
    dispatch(fetchFollowers(otherProfile))
  }
}

const unloadFollowers = () => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_FOLLOWERS', followers: []})
  }
}

const fetchFollowings = (otherProfile=false) => {
  return (dispatch, getState) => {
    const url = otherProfile ? `/api/v2/users/${getState().user.otherUser.id}/followings.json` : '/api/v2/followings.json'
    axios.get(url)
      .then((response) => {
        dispatch({type: 'SET_FOLLOWINGS', followings: response.data})
        dispatch({type: 'SET_FOLLOWINGS_LOADING', loading: false})
      })
      .catch(() => {
        dispatch({type: 'SET_FOLLOWINGS_LOADING', loading: false})
      })
  }
}

const loadFollowings = (otherProfile=false) => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_FOLLOWINGS_LOADING', loading: true})
    dispatch(fetchFollowings(otherProfile))
  }
}

const unloadFollowings = () => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_FOLLOWINGS', followings: []})
  }
}

const followUser = (userId) => {
  return (dispatch, getState) => {
    axios.post(`/api/v2/users/${userId}/follow`)
      .then(() => {
        dispatch(reloadUser())
        if(!getState().user.viewingOtherUser) {
          dispatch(fetchFollowings())
        }
      })
  }
}

const unfollowUser = (userId) => {
  return (dispatch, getState) => {
    axios.delete(`/api/v2/users/${userId}/unfollow`)
      .then(() => {
        dispatch(reloadUser())
        if(!getState().user.viewingOtherUser) {
          dispatch(fetchFollowings())
        }
      })
  }
}

export { loadFollowers, unloadFollowers, loadFollowings, unloadFollowings, followUser, unfollowUser, fetchFollowers, fetchFollowings }