import React, { useState, useEffect } from "react"
import CheckIcon from '../images/check.svg'

const radioPanel = ({ input, label, type, image, meta: { touched, error } }) => {
  
  return (
  <div className="panel-button">
    <label htmlFor={input.id} className={input.checked && "checked" || ""}>
      <input {...input} type="radio" style={{display: "none"}} />
      <div className="icon">
        {React.createElement(image)}
      </div>
      <span>{label}</span>
      <div className="check"><CheckIcon/></div>
    </label>
  </div>
  )
}


export { radioPanel }