import axios from 'axios'
import { reset } from 'redux-form'
import { openGearSelectionModalWithCallback } from './gearSelectionActions'
import { openModal, closeModal } from './modalActions'
const { updateSyncErrors } = require('redux-form/lib/actions').default;
import { formatDateToYYYYMMDD } from '../utils/documentUtils'

const loadGear = (otherUser=false, userId=null) => {
  return (dispatch, getState) => {
    dispatch({type: 'UNLOAD_ALL_GEAR'})    
    dispatch({type: 'SET_GEAR_LOADING', loading: true})
    const url = otherUser ? `/api/v2/users/${userId}/users_gears.json` : '/api/v2/users_gears.json'
    console.log(url)
    axios.get(url)
      .then((response) => {
        console.log("users gears response data: ", response.data)
        dispatch({type: 'LOAD_ALL_GEAR', data: response.data, editable: !otherUser})
      })
      .catch((error) => {
        dispatch({type: 'SET_GEAR_LOADING', loading: false})
      })
  }
}

const unloadGear = () => {
  return (dispatch, getState) => {
    dispatch({type: 'UNLOAD_ALL_GEAR'})    
  }
}

const addGear = (gearType) => {
  return (dispatch, getState) => {
    dispatch(openGearSelectionModalWithCallback(false, gearType, (gearId) => { 
      axios.post('/api/v2/users_gears.json', { gear_id: gearId })
      .then((response) => {
        console.log("users gears response data: ", response.data)
        dispatch({type: 'LOAD_ALL_GEAR', data: response.data, editable: true})
      })
      .catch((error) => {
      })
    }))
  }
}

const removeGear = (usersGear) => {
  return (dispatch, getState) => {
    axios.delete(`/api/v2/users_gears/${usersGear.id}.json`)
      .then((response) => {
        console.log("users gears response data: ", response.data)
        dispatch({type: 'LOAD_ALL_GEAR', data: response.data, editable: true})
      })
      .catch((error) => {
      })
  }
}

const openGearDetailsModal = (usersGear) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ACTIVE_USERS_GEAR', data: usersGear })
    dispatch(reset('gear_details'))
    dispatch(openModal('GEAR_DETAILS_MODAL'))
  }
}

const saveGearDetails = (formParams) => {
  return (dispatch, getState) => {
    console.log("gear details form params", formParams)
    const id = formParams.gear_details.id

    const formData = new FormData()
    
    //issues with sending the damn dates
    formData.append('users_gear[serial_number]', formParams.gear_details.serial_number || '')
    formData.append('users_gear[purchased_date]', (formParams.gear_details.purchased_date && formatDateToYYYYMMDD(formParams.gear_details.purchased_date)) || '')
    formData.append('users_gear[purchased_from]', formParams.gear_details.purchased_from || '')
    formData.append('users_gear[price]', formParams.gear_details.price || '')
    formData.append('users_gear[notes]', formParams.gear_details.notes || '')
    formData.append('users_gear[strings_used]', formParams.gear_details.strings_used || '')
    formData.append('users_gear[pickups_used]', formParams.gear_details.pickups_used || '')
    formData.append('users_gear[last_string_change]', (formParams.gear_details.last_string_change && formatDateToYYYYMMDD(formParams.gear_details.last_string_change)) || '')
    formData.append('users_gear[string_change_reminder]', (formParams.gear_details.string_change_reminder && formatDateToYYYYMMDD(formParams.gear_details.string_change_reminder)) || '')

    if(formParams.gear_details.additional_data) {
      formParams.gear_details.additional_data.forEach((data, index) => {
        formData.append(`users_gear[additional_data_attributes][${index}][name]`, data.name)
        formData.append(`users_gear[additional_data_attributes][${index}][value]`, data.value)
      })
    }

    formParams.gear_details.images.forEach((image, index) => {
      formData.append(`users_gear[images_attributes][${index}][id]`, image.id)
      if(image.file) {
        formData.append(`users_gear[images_attributes][${index}][file]`, image.file)
      }
      else {
        formData.append(`users_gear[images_attributes][${index}][url]`, image.url)
      }
    })

    axios.put(`/api/v2/users_gears/${id}.json`, formData)
      .then(response => {
        const data = response.data
        if(data.errors) {
          dispatch(updateSyncErrors('gear_details', {
            gear_details: {
              ...data.errors
            }
          }))
        }
        else {
          console.log("users gears response data: ", response.data)
          dispatch({type: 'LOAD_ALL_GEAR', data: data, editable: true})
          dispatch(closeModal())
        }
      })
      .catch(error => dispatch({type: 'USERS_GEAR_SAVE_FAILURE'}))
  }
}

const openGearInfoModal = (usersGear) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ACTIVE_USERS_GEAR', data: usersGear })
    dispatch(openModal('GEAR_INFO_MODAL'))
  }
}

export { loadGear, unloadGear, addGear, removeGear, openGearDetailsModal, saveGearDetails, openGearInfoModal }