import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import LoginModal from './modals/LoginModal'
import SignupModal from './modals/SignupModal'
import LoggedInModal from './modals/LoggedInModal'
import ToastModal from './modals/ToastModal'
import OnboardingStep1 from './modals/OnboardingStep1'
import OnboardingStep2 from './modals/OnboardingStep2'
import OnboardingStep3 from './modals/OnboardingStep3'
import FavoriteInstrumentStep from './modals/FavoriteInstrumentStep'
import FavoritePedalStep from './modals/FavoritePedalStep'
import FavoriteAmpStep from './modals/FavoriteAmpStep'
import WelcomeModal from './modals/WelcomeModal'
import NewSignalChainModal from './modals/NewSignalChainModal'
import SignalChainDetailsModal from './modals/SignalChainDetailsModal'
import SignalChainInfoModal from './modals/SignalChainInfoModal'
import SelectGearModal from './modals/SelectGearModal'
import GenericGearSelectionModal from './modals/GenericGearSelectionModal'
import GearDetailsModal from './modals/GearDetailsModal'
import GearInfoModal from './modals/GearInfoModal'
import { closeModal } from '../actions/modalActions'
import CrossIcon from '../images/cross.svg'

const Modal = ( {opened, modalSide, animate, close, openedModal }) => {
  const [modalNode, setModalNode] = useState()
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  
  useEffect(() => {
    let header = document.getElementById('header')
    if(opened) {
      document.body.style.overflow = 'hidden'
      document.body.style.marginRight = `${scrollbarWidth}px`
      if(header) {
        header.style.paddingRight = `${120 + scrollbarWidth}px`
      }
    }
    else {
      document.body.style.overflow= 'auto'
      document.body.style.marginRight = '0';
      if(header) {
        header.style.paddingRight = '120px'
      }
    }
  }, [opened])

  useEffect(() => {
    // Calculate the scrollbar width on mount
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    setScrollbarWidth(scrollbarWidth);

    return () => {
      // Cleanup when component unmounts
      setScrollbarWidth(0);
    };
  }, []);
  
  const renderModal = (m) => {
    switch(m) {
      case 'LOGIN': return <LoginModal/>
      case 'SIGNUP': return <SignupModal/>
      case 'LOGGEDIN': return <LoggedInModal/>
      case 'TOAST': return <ToastModal/>
      case 'ONBOARDINGSTEP1': return <OnboardingStep1/>
      case 'ONBOARDINGSTEP2': return <OnboardingStep2/>
      case 'ONBOARDINGSTEP3': return <OnboardingStep3/>
      case 'FAVORITEINSTRUMENTSTEP': return <FavoriteInstrumentStep/>
      case 'FAVORITEPEDALSTEP': return <FavoritePedalStep/>
      case 'FAVORITEAMPSTEP': return <FavoriteAmpStep/>
      case 'NEW_SIGNAL_CHAIN': return <NewSignalChainModal/>
      case 'SIGNAL_CHAIN_DETAILS': return <SignalChainDetailsModal/>
      case 'SIGNAL_CHAIN_INFO': return <SignalChainInfoModal/>
      case 'SELECT_GEAR': return <SelectGearModal/>
      case 'WELCOME': return <WelcomeModal/>
      case 'GENERIC_GEAR_SELECTION': return <GenericGearSelectionModal/>
      case 'GEAR_DETAILS_MODAL': return <GearDetailsModal/>
      case 'GEAR_INFO_MODAL': return <GearInfoModal/>
      default: return <div/>
    }
  }
  
  return (
    <div className={'modal-backdrop ' + (opened ? 'modal-open': '')} onClick={e => { if (!modalNode.contains(e.target)) /*close()*/; } }>
      <div className={`modal-body modal-body-${modalSide} ${animate ? 'animate' : ''}` } ref={node => setModalNode(node)}>
        <div className="modal-close" onClick={close} ><CrossIcon/></div>
        <div className="modal-content">
          <div style={{display: 'flex'}}>
            { renderModal(openedModal) }
          </div>
        </div>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  openedModal: state => state.modal.modal,
  opened: state => state.modal.modalOpen,
  modalSide: state => state.modal.modalSide,
  animate: state => state.modal.animate
})

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(closeModal())
  }
}

export default connect(selector, mapDispatchToProps)(Modal);