import React, { useState, useEffect, useCallback } from 'react'
import Dropzone from 'react-dropzone'
import { v4 } from 'uuid'

import RemoveIcon from '../images/cross.svg'


const imageUploadField = ({ input, label, multiple, meta: { touched, error, active } }) => {

  const onDrop = useCallback((acceptedFiles) => {
    let files = []
    if(!multiple && acceptedFiles.length > 1) {
      acceptedFiles = [acceptedFiles[0]]
    }
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onloadend = () => {
        input.onChange((prevImages) => [...(prevImages || []), {
          url: reader.result,
          file: file,
          id: v4()
        }])
      }
      reader.readAsDataURL(file)
    })
  }, [])
  
  const removeImage = (id) => {
    const newImages = input.value.filter((i) => i.id !== id)
    input.onChange(newImages)
  }

  return (
    <div className='form-group'>
      <label>{label}</label>
      <div className="multi-image-upload">
        { (input.value || []).map((image, i) => {
          return (
            <div className="photo" key={i}>
              <img src={image.url}/>
              <div className="remove" onClick={() => removeImage(image.id)}><RemoveIcon/></div>
            </div>
          )
        })}
        { ((input.value || []).length < 1 || multiple) && (
          <Dropzone onDrop={onDrop} accept={{'image/png': ['.png'], 'image/jpg': ['.jpg', '.jpeg']}} multiple={multiple}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="photo-dropzone">+ Add Photo</div>
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  )
}

export { imageUploadField }