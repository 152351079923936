import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import gearSelection from '../../fieldRenderers/gearSelection'
import { submitFavoriteInstrumentStep } from '../../actions/userActions'
import { transitionModalLeft, transitionModalRight } from '../../actions/modalActions'
import ArrowBack from '../../images/arrow_back.svg'
import InfoTooltip from '../controls/InfoTooltip'

let InstrumentForm = ({ handleSubmit, skip }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <Field name="user[favorite_instrument_id]" component={gearSelection} gearType="instrument"/>
      <div className="buttons">
        <div className="form-group">
          <input className="continue" name='submit' type='submit' value="Continue"/>
        </div>
        <div className="form-group">
          <input className="skip" name='submit' type='submit' value="Skip" onClick={ skip }/>
        </div>
      </div>
    </form>
  )
}

InstrumentForm = reduxForm({
  form: 'onboarding_instrument_step'
})(InstrumentForm)

InstrumentForm = connect()(InstrumentForm)

const FavoriteInstrumentStep = ({ back, skip, submit }) => {
  return (
    <div className="favorites-modal">
      <div className="modal-header">
        <div className="back-button" onClick={ back }><ArrowBack/></div>
        <h2>What is your favorite instrument that you own?</h2>
        <p>Why do we ask you this?<InfoTooltip>Lorem ipsum dolor sit, consecte tur adipislla cond malesu porem ipsum dolor sit, consectetur adipislla cond lesuada auctor.</InfoTooltip></p>
      </div>
      <InstrumentForm onSubmit={ submit } skip={ skip }/>
    </div>
  )
}

const selector = createStructuredSelector({
  loginError: state => state.user.loginError
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitFavoriteInstrumentStep(params)),
    back: () => dispatch(transitionModalLeft('ONBOARDINGSTEP3')),
    skip: () => dispatch(transitionModalRight('FAVORITEPEDALSTEP'))
  }
}

export default connect(selector, mapDispatchToProps)(FavoriteInstrumentStep)