import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, FieldArray, reduxForm, change, touch, initialize } from 'redux-form'
import { required } from 'redux-form-validators'
import { createStructuredSelector } from 'reselect'
import { textField } from '../../fieldRenderers/textField'
import { textAreaField } from '../../fieldRenderers/textAreaField'
import { radioSelect } from '../../fieldRenderers/radioSelect'
import { selectField } from '../../fieldRenderers/selectField'
import { imageUploadField } from '../../fieldRenderers/imageUploadField'
import { multiAudioClipUploadField } from '../../fieldRenderers/multiAudioClipUploadField'
import { linksFieldArray } from '../../fieldRenderers/linksFieldArray'
import { saveSignalChainDetails } from '../../actions/signalChainActions'

import ComputerIcon from '../../images/computer.svg'
import OverviewKeyIcon from '../../images/overview_key.svg'


let DetailsForm = ({ handleSubmit, initialValues, genreOptions, dawOptions }) => {
  const reduxDispatch = useDispatch()
  const signalChainValues = useSelector(state => state.signalChain && state.signalChain.signalChain);

  // this is here to initialize the form from the existing signal chain  
  useEffect(() => {
    if(signalChainValues) {
      console.log("signalChainValues", signalChainValues)
      reduxDispatch(initialize('signal_chain', { signal_chain: signalChainValues }))
      Object.keys(signalChainValues).forEach(fieldName => {
        reduxDispatch(touch('signal_chain', fieldName))
      });
    }
  }, [signalChainValues])

//        <Field label="Signal Chain Type" name='signal_chain[signal_chain_type]' values={[{ value: 'digital', label: "Plugin Chain", icon: ComputerIcon },{ value: 'physical', label: "Pedal Chain", icon: OverviewKeyIcon }]}component={radioSelect}/>
  
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <div className="modal-form-scroll">
        <Field label="Name" name='signal_chain[name]' component={textField} type='text' validate={[required()]}/>
        <Field label="Genre" name="signal_chain[genre_id]" component={selectField} options={genreOptions} placeholder="Select Genre"/>
        <Field label="Description" name='signal_chain[description]' component={textAreaField} validate={[required()]}/>
        <Field label="DAW Used" name="signal_chain[daw_id]" component={selectField} options={dawOptions} placeholder="Select DAW"/>
        <Field label="Venue" name='signal_chain[venue]' component={textField} type='text' placeholder="Enter Venue"/>
        <FieldArray label="Reference Links" addLabel="Add Website" name='signal_chain[reference_links]' component={linksFieldArray} />
        <Field label="Photos" name='signal_chain[images]' component={imageUploadField} multiple={true} />
        <Field label="Audio Clips" name='signal_chain[audio_clips]' component={multiAudioClipUploadField} multiple/>
      </div>
      <div className="form-group submit-button" style={{'marginTop': '16px'}}>
        <input name='submit' type='submit' value="Save Signal Chain"/>
      </div>
    </form>
  )
}

DetailsForm = reduxForm({
  form: 'signal_chain'
})(DetailsForm)

DetailsForm = connect((state, ownProps) => {
  return {
    genreOptions: state.formData.genres.map((g) => ({ value: g.id, label: g.name })),
    dawOptions: state.formData.daws.map((d) => ({ value: d.id, label: d.name }))
  }
})(DetailsForm)

const SignalChainDetailsModal = ({ submit, errors }) => {
  return (
    <div className="signal-chain-details-modal form-modal">
      <div className="modal-body">
        <div className="modal-header">
          <h2>Edit Signal Chain Details</h2>
        { !errors && 
          (
            <p>Change the details of your signal chain below</p>
          )
        }
        { errors && errors.map((error, i) => {
          return (
            <p key={ i }>{error}</p>
          )
        })}
        </div>
        <DetailsForm onSubmit={ submit }/>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  errors: state => []
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(saveSignalChainDetails(params)),
  }
}

export default connect(selector, mapDispatchToProps)(SignalChainDetailsModal);