const initialState = {
  id: undefined,
  email: undefined,
  loggedIn: false,
  loginError: '',
  passwordErrors: [],
  currentUser: {},
  otherUser: {},
  viewingOtherUser: false,
  otherUserLoading: true
}

const parseUser = (data) => {
  return {
    id: data.id,
    email: data.email,
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    bio: data.bio,
    createdAt: new Date(Date.parse(data.createdAt)),
    primaryInstrument: data.primaryInstrument,
    followingIds: data.followingIds,
    followerCount: data.followerCount,
    genres: data.genres,
    daws: data.daws,
    favoriteInstrument: data.favoriteInstrument,
    favoritePedal: data.favoritePedal,
    favoriteAmp: data.favoriteAmp,
    favoriteInstrumentId: data.favoriteInstrument && data.favoriteInstrument.id,
    favoritePedalId: data.favoritePedal && data.favoritePedal.id,
    favoriteAmpId: data.favoriteAmp && data.favoriteAmp.id,
    avatar: data.profileImage && [{ url: data.profileImage.url, id: data.profileImage.id }],
  }
}

function userReducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_INITIAL_USER_DATA':
      return {...state,
        id: action.data.id,
        email: action.data.email,
        currentUser: parseUser(action.data),
        loggedIn: !!action.data.email
      }
    case 'SET_USER':
      console.log("setting user", action.data)
      return {...state,
        id: action.data.id,
        email: action.data.email,
        currentUser: parseUser(action.data),
        loggedIn: true,
        loginError: null
      }
    case 'SET_OTHER_USER':
      console.log("setting other user", action.data)
      return {...state,
        otherUser: parseUser(action.data),
        viewingOtherUser: true,
        otherUserLoading: false
      }
    case 'UNSET_OTHER_USER':
      return {...state,
        otherUser: {},
        viewingOtherUser: false,
        otherUserLoading: true
      }
    case 'LOGOUT_USER':
      return {...state,
        id: null,
        email: null,
        loggedIn: false
      }
    case 'LOGIN_FAILURE':
      return {...state,
        loginError: 'Invalid Email or Password'
      }
    case 'PASSWORD_CHANGE_FAILURE':
      return {...state,
        passwordErrors: action.errors
      }
    case 'PASSWORD_CHANGE_SUCCESS':
      return {...state,
        passwordErrors: []
      }
    default: return state
  }
}

export default userReducer