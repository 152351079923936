import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import modalReducer from '../reducers/modalReducer'
import userReducer from '../reducers/userReducer'
import loggingReducer from '../reducers/loggingReducer'
import signalChainReducer from '../reducers/signalChainReducer'
import formDataReducer from '../reducers/formDataReducer'
import gearSelectionReducer from '../reducers/gearSelectionReducer'
import gearReducer from '../reducers/gearReducer'
import followingReducer from '../reducers/followingReducer'
import exploreReducer from '../reducers/exploreReducer'
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
  modal: modalReducer,
  user: userReducer,
  logging: loggingReducer,
  signalChain: signalChainReducer,
  gear: gearReducer,
  gearSelection: gearSelectionReducer,
  following: followingReducer,
  explore: exploreReducer,
  form: formReducer,
  formData: formDataReducer
})

export default function configureStore() {
  const store = createStore(rootReducer, {}, compose(applyMiddleware(thunk)));
  return store;
}