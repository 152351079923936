import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { orderedSignalChainGear } from '../../utils/signalChainUtils'
import { timeFormatter } from '../../utils/documentUtils'
import { AudioClipPlayer } from '../controls/AudioClipPlayer'

import Line from '../../images/line-9.svg'
import ComputerIcon from '../../images/computer.svg'
import OverviewKeyIcon from '../../images/overview_key.svg'

const SignalChainInfoModal = ({ signalChain, editable }) => {
  return (
    <div className="signal-chain-info-modal info-modal">
      <div className="modal-body">
        <div className="modal-header">
          <h2>{signalChain.name}</h2>
        </div>
        <div className="column">
          <div className="column-scroll">
            <div className="signal-chain-window">
              <div className="signal-chain-window-scroll">
                <div className="signal-chain">
                  { orderedSignalChainGear(signalChain).map((item, i) => {
                    console.log("gear item", item)
                    if(item.type == 'gear') {
                      return (
                        <div className="gear-item" key={i}>
                          <div className="image">
                            <img src={item.gear_item.image_src}/>
                          </div>
                          <div className="name">
                            <h4>{item.gear_item.name}</h4>
                          </div>
                        </div>
                      )
                    }
                    else {
                      return (
                        <div className="connection" key={i}>
                          <Line/>
                        </div>
                      )
                    }
                  })}
                  <div className="end-spacer"/>
                </div>
              </div>
            </div>
            <div className="info-group">
              <h4>Description</h4>
              <p>{signalChain.description}</p>
            </div>
            <div className="row-group">
              <div className="info-group">
                <h4>Signal Chain Type</h4>
                <p>
                  <span className="pill cursor-auto yellow-text">
                  {signalChain.signal_chain_type == 'physical' ? (
                    <OverviewKeyIcon/>
                  ) : (
                    <ComputerIcon/>
                  )}
                  {signalChain.signal_chain_type == 'physical' ? (
                    <span>Pedal Chain</span>
                  ) : (
                    <span>Plugin Chain</span>
                  )}
                  </span>
                </p>
              </div>
              <div className="info-group">
                <h4>Genre</h4>
                <p>{signalChain.genre || 'No genre specified'}</p>
              </div>
            </div>
            <div className="row-group">
              <div className="info-group">
                <h4>No. of Gear</h4>
                <p>{signalChain.gear.length}</p>
              </div>
              <div className="info-group">
                <h4>Date Created</h4>
                <p>{timeFormatter(signalChain.created_at)}</p>
              </div>
            </div>
            <div className="info-group">
              <h4>Venue</h4>
              <p>{signalChain.venue || 'No venue specified'}</p>
            </div>
            { signalChain.reference_links && (signalChain.reference_links.length > 0) && (
              <div className="links-group">
                <h4>Reference Links</h4>
                { signalChain.reference_links.map((link, i) => {
                  return (
                    <div className="reference-link" key={i}>
                      <div className="title">
                        <label>{i}. Title</label>
                        <input type="text" disabled value={link.title} className="pointer-auto"/>
                      </div>
                      <div className="link">
                        <label>Link</label>
                        <input type="text" disabled value={link.link} className="pointer-auto"/>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            { signalChain.images && signalChain.images.length > 0 && (
              <div className="info-group">
                <h4>Photos</h4>
                <div className="images-group">
                  { signalChain.images.map((image, i) => {
                    return (
                      <div className="image" key={i}>
                        <img src={image.url}/>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            { signalChain.audio_clips && signalChain.audio_clips.length > 0 && (
              <div className="info-group width-100">
                <h4>Audio Clips</h4>
                <div className="audio-clip-group">
                  { signalChain.audio_clips.map((clip, i) => {
                    return (
                      <AudioClipPlayer clip={clip} key={i}/>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  signalChain: state => state.signalChain.signalChain,
  editable: state => state.signalChain.editable
})

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(selector, mapDispatchToProps)(SignalChainInfoModal);