import axios from 'axios'
import { reset } from 'redux-form'
import { closeModal, transitionModalRight, toast } from './modalActions'
import { waitFor } from '../utils/promises'
const { updateSyncErrors } = require('redux-form/lib/actions').default;


function login(formValues, navigate) {
  return (dispatch, getState) => {
    axios.post('/api/v2/users/sign_in', formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
      .then(() => transitionModalRight('LOGGEDIN')(dispatch, getState))
      .then(() => waitFor(1000))
      .then(() => navigate('/profile/gear'))
      .then(() => waitFor(1000))
      .then(() => closeModal()(dispatch, getState))
      .catch(error => dispatch({type: 'LOGIN_FAILURE'}))
  }
}

function logout(navigate) {
    return (dispatch, getState) => {
      axios.delete('/api/v2/users/session.json')
        .then(response => dispatch({ type: 'LOGOUT_USER' }))
        .then(() => dispatch(toast("Logged Out Successfully", "Come back soon!")))
        .then(() => navigate('/'))
        .catch(error => console.log(error))
  }
}

function signup(formValues) {
  return (dispatch, getState) => {
    axios.post('/api/v2/users/sign_up.json', formValues)
      .then(response => {
        let data = response.data
        if(data.errors) {
          dispatch(updateSyncErrors('signup', {
            user: {
              ...data.errors
            }
          }));
        }
        else {
          dispatch({type: 'SET_USER', data: response.data })
          transitionModalRight('ONBOARDINGSTEP1')(dispatch, getState)
        }
      })
//      .then(() => closeModal()(dispatch, getState))
      .catch(error => dispatch({type: 'LOGIN_FAILURE'}))
  }
}

function submitOnboardingStep1(formValues) {
  return (dispatch, getState) => {
    console.log("submitting onboarding step 1", formValues)
    let user_id = getState().user.id
    axios.put(`/api/v2/users/${user_id}`, formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
    transitionModalRight('ONBOARDINGSTEP2')(dispatch, getState)
  }
}

function submitOnboardingStep2(formValues) {
  return (dispatch, getState) => {
    console.log("submitting onboarding step 2", formValues)
    let user_id = getState().user.id
    formValues = {
      user: {
        genre_ids: formValues.user.genres.map((g) => g.id)
      }
    }
    axios.put(`/api/v2/users/${user_id}`, formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
    transitionModalRight('ONBOARDINGSTEP3')(dispatch, getState)
  }
}

function submitOnboardingStep3(formValues) {
  return (dispatch, getState) => {
    console.log("submitting onboarding step 3", formValues)
    let user_id = getState().user.id
    formValues = {
      user: {
        daw_ids: formValues.user.daws.map((d) => d.id)
      }
    }
    axios.put(`/api/v2/users/${user_id}`, formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
    transitionModalRight('FAVORITEINSTRUMENTSTEP')(dispatch, getState)
  }
}

function submitFavoriteInstrumentStep(formValues) {
  return (dispatch, getState) => {
    console.log("submitting onboarding instrument step", formValues)
    let user_id = getState().user.id
    axios.put(`/api/v2/users/${user_id}`, formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
    transitionModalRight('FAVORITEPEDALSTEP')(dispatch, getState)
  }
}

function submitFavoritePedalStep(formValues) {
  return (dispatch, getState) => {
    console.log("submitting onboarding pedal step", formValues)
    let user_id = getState().user.id
    axios.put(`/api/v2/users/${user_id}`, formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
    transitionModalRight('FAVORITEAMPSTEP')(dispatch, getState)
  }
}

function submitFavoriteAmpStep(formValues) {
  return (dispatch, getState) => {
    console.log("submitting onboarding amp step", formValues)
    let user_id = getState().user.id
    axios.put(`/api/v2/users/${user_id}`, formValues)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
    transitionModalRight('WELCOME')(dispatch, getState)
  }
}

function submitUserSettingsForm(formValues) {
  return (dispatch, getState) => {
    const userId = getState().user.id
    let formData = new FormData()
    formData.append('user[first_name]', formValues.user.first_name)
    formData.append('user[last_name]', formValues.user.last_name)
    formData.append('user[username]', formValues.user.username)
    formData.append('user[email]', formValues.user.email)
    formData.append('user[primary_instrument]', formValues.user.primary_instrument)
    formData.append('user[bio]', formValues.user.bio || '')
    formData.append('user[favorite_instrument_id]', formValues.user.favorite_instrument_id)
    formData.append('user[favorite_pedal_id]', formValues.user.favorite_pedal_id)
    formData.append('user[favorite_amp_id]', formValues.user.favorite_amp_id)
    if(formValues.user.genres.length > 0) {
      formValues.user.genres.forEach((g) => formData.append('user[genre_ids][]', g.id))
    }
    else {
      formData.append('user[genre_ids][]', "")
    }
    if(formValues.user.daws.length > 0) {
      formValues.user.daws.forEach((g) => formData.append('user[daw_ids][]', g.id))
    }
    else {
      formData.append('user[daw_ids][]', "")
    }
    if(formValues.user.avatar) {
      formValues.user.avatar.forEach((image, index) => {
        formData.append(`user[avatar]`, image.file)
      })
    }
    axios.put(`/api/v2/users/${userId}`, formData)
      .then(response => {
        let data = response.data
        if(data.errors) {
          dispatch(updateSyncErrors('user_settings', {
            user: {
              ...data.errors
            }
          }))
          dispatch(toast('Error Submitting Form', 'Please check your entries and try again'))
        }
        else {
          dispatch({type: 'SET_USER', data: response.data })
          dispatch(reset('user_settings'))
          dispatch(toast('User Updated Successfully'))
        }
      })
//      .then(() => closeModal()(dispatch, getState))
      .catch(error => dispatch({type: 'LOGIN_FAILURE'}))
  }
}

function submitChangePassword(formValues) {
  return (dispatch, getState) => {
    const userId = getState().user.id
    axios.put(`/api/v2/users/${userId}/change_password`, formValues)
      .then(response => {
        dispatch(reset('change_password'))
        dispatch({type: 'PASSWORD_CHANGE_SUCCESS'})
      })
      .catch(error => {
        console.log(error)
        let errors = error.response.data.error
        if(errors == 'Invalid existing password') {
          errors = [errors]
        }
        dispatch({type: 'PASSWORD_CHANGE_FAILURE', errors: errors})
      })
  }
}

const reloadUser = () => {
  return (dispatch, getState) => {
    const userId = getState().user.id
    axios.get(`/api/v2/users/${userId}.json`)
      .then(response => dispatch({type: 'SET_USER', data: response.data }))
  }
}

const loadOtherUser = (userId) => {
  return (dispatch, getState) => {
    axios.get(`/api/v2/users/${userId}.json`)
      .then(response => dispatch({type: 'SET_OTHER_USER', data: response.data }))
  }
}

const unloadOtherUser = () => {
  return (dispatch, getState) => {
    dispatch({type: 'UNSET_OTHER_USER' })
  }
}

export { login, 
         logout, 
         signup, 
         submitOnboardingStep1, 
         submitOnboardingStep2, 
         submitOnboardingStep3, 
         submitFavoriteInstrumentStep, 
         submitFavoritePedalStep, 
         submitFavoriteAmpStep, 
         submitUserSettingsForm, 
         submitChangePassword,
         reloadUser,
         loadOtherUser,
         unloadOtherUser
       }