import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { capitalizeEachWord, parseDateStringToDate, timeFormatter} from '../../utils/documentUtils'

import ReverbIcon from '../../images/reverb_icon.svg'

const GearInfoModal = ({ usersGear, editable }) => {
  return (
    <div className="gear-info-modal split-info-modal">
      <div className="modal-body">
        <div className="modal-header">
          <h2>{usersGear.gear.name}</h2>
        </div>
        <div className="column-container">
          <div className="left-column">
            <div className="image">
              <img src={usersGear.gear.image_src}/>
            </div>
          </div>
          <div className="right-column">
            <div className="right-column-scroll">
              <div className="info-group">
                <h4>Description</h4>
                <p>{usersGear.gear.description || usersGear.gear.name}</p>
              </div>
              <a className="button button-primary" href={`https://reverb.com/p/${usersGear.gear.reverb_slug}`} target="_blank"><ReverbIcon/>Buy it on Reverb</a>
              <div className="row-group">
                <div className="info-group">
                  <h4>Owned By</h4>
                  <p>{usersGear.gear.user_count}</p>
                </div>
                <div className="info-group">
                  <h4># of Signal Chains Used In</h4>
                  <p>{usersGear.gear.signal_chain_count}</p>
                </div>
              </div>
              <div className="row-group">
                <div className="info-group">
                  <h4>Category</h4>
                  <p>{usersGear.gear.category}</p>
                </div>
                <div className="info-group">
                  <h4>Brand</h4>
                  <p>{usersGear.gear.brand}</p>
                </div>
              </div>
              { (usersGear.serial_number || usersGear.purchased_date) && editable && (
                <div className="row-group">
                  <div className="info-group">
                    <h4>Serial Number</h4>
                    <p>{usersGear.serial_number || "-"}</p>
                  </div>
                  <div className="info-group">
                    <h4>Date Purchased</h4>
                    <p>{(usersGear.purchased_date && timeFormatter(parseDateStringToDate(usersGear.purchased_date))) || '-'}</p>
                  </div>
                </div>
              )}
              { (usersGear.purchased_from || usersGear.price) && editable && (
                <div className="row-group">
                  <div className="info-group">
                    <h4>Purchased From</h4>
                    <p>{usersGear.purchased_from || "-"}</p>
                  </div>
                  <div className="info-group">
                    <h4>Purchase Price</h4>
                    <p>{(usersGear.price && (`$${parseInt(usersGear.price).toFixed(2)}`)) || '-'}</p>
                  </div>
                </div>
              )}
              { usersGear.notes && editable && (
                <div className="info-group">
                  <h4>Notes</h4>
                  <p>{usersGear.notes || "-"}</p>
                </div>
              )}
              { (usersGear.additional_data && usersGear.additional_data.length > 0) && editable && usersGear.additional_data.map((data, i) => {
                return (
                  <div className="info-group" key={i}>
                    <h4>{data.name}</h4>
                    <p>{data.value}</p>
                  </div>
                )
              })}
              { (usersGear.images.length > 0) && (
                <div className="info-group">
                  <h4>Photos</h4>
                  <div className="photos-group">
                    { usersGear.images.map((image, i) => {
                      return (
                      <div key={i} className="photo"><img src={image.url}/></div>
                    )})}
                  </div>
                </div>
              )}
              { (usersGear.strings_used || usersGear.pickups_used) && editable && (
                <div className="row-group">
                  <div className="info-group">
                    <h4>Strings Used</h4>
                    <p>{usersGear.strings_used || "-"}</p>
                  </div>
                  <div className="info-group">
                    <h4>Pickups Used</h4>
                    <p>{usersGear.pickups_used || "-"}</p>
                  </div>
                </div>
              )}
              { (usersGear.last_string_change || usersGear.string_change_reminder) && editable && (
                <div className="row-group">
                  <div className="info-group">
                    <h4>Last String Change</h4>
                    <p>{(usersGear.last_string_change && timeFormatter(parseDateStringToDate(usersGear.last_string_change))) || '-'}</p>
                  </div>
                  <div className="info-group">
                    <h4>String Change Reminder</h4>
                    <p>{(usersGear.string_change_reminder && timeFormatter(parseDateStringToDate(usersGear.string_change_reminder))) || '-'}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  usersGear: state => state.gear.activeUsersGear,
  editable: state => state.gear.editable
})

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(selector, mapDispatchToProps)(GearInfoModal);