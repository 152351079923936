
// this will need updating if we ever allow loops or splits
function orderedSignalChainGear(sc) {
    console.log("signal chain", sc)
    let processed = []
    let currentGear = sc.gear.filter((g) => g.root)[0]
    currentGear['type'] = 'gear'
    let gearList = [currentGear]
    while(currentGear && currentGear.outputs.length > 0) {
        gearList.push({type: 'connection', from: currentGear.id, connectionId: currentGear.outputs[0].id, id: undefined}) // the undefined id here is a leftover from a previous implementation, needs to be refactored
        let nextGear = sc.gear.filter((g) => g.id == currentGear.outputs[0].to)[0]
        nextGear['type'] = 'gear'
        gearList.push(nextGear)
        currentGear = nextGear
    }
    return gearList    
}

export { orderedSignalChainGear }