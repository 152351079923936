import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loadFollowings, unloadFollowings, followUser, unfollowUser } from '../../../actions/followingActions'
import PopupMenu from '../../../components/controls/PopupMenu'

import defaultProfileImage from '../../../images/default-profile-image.jpg'

import FolderOffIcon from '../../../images/folder_off.svg'
import MenuIcon from '../../../images/more_vert.svg'
import FollowIcon from '../../../images/person_add.svg'
import UnfollowIcon from '../../../images/person_remove.svg'
import ProfileIcon from '../../../images/account_box.svg'
import SearchIcon from '../../../images/search.svg'

const SmallUserPanel = ({ user, index }) => {
  const menuButtonRef = useRef()
  const following = useSelector((state) => state.user.currentUser.followingIds.includes(user.id))
  const isCurrentUser = useSelector((state) => user.id == state.user.id)
  const navigate = useNavigate()
  
  const dispatch = useDispatch()
  
  const unfollow = () => {
    dispatch(unfollowUser(user.id))
  }
  
  const follow = () => {
    dispatch(followUser(user.id))
  }

  const goToProfile = () => {
    if(isCurrentUser) {
      navigate(`/profile/gear`)
    }
    else {
      navigate(`/users/${user.id}/profile`)
    }
  }
  
  const goHome = () => {
    navigate(`/profile/gear`)
  }
  
  return (
    <div className="user" style={{zIndex: -index}}>
      <div className="profile-image" onClick={goToProfile}>
        { user.profile_image_url ? (
          <img src={user.profile_image_url}/>
        ) : (
          <img src={defaultProfileImage}/>
        )}
      </div>
      <div className="info" onClick={goToProfile}>
        <h4>{user.name}</h4>
        <p>{user.username}</p>
      </div>
      <div className="controls">
        { following && !isCurrentUser && (
          <div className="button button-tertiary small no-pointer">Following</div>
        )}
        { !following && !isCurrentUser && (
          <div className="button button-secondary small" onClick={follow}>Follow</div>
        )}
      </div>
      <div className="menu-button" ref={menuButtonRef}><MenuIcon/></div>
      { !isCurrentUser && (
        <PopupMenu className="user-menu" triggeringRef={menuButtonRef} options={[
          { label: following ? 'Unfollow' : 'Follow', icon: following ? UnfollowIcon : FollowIcon, onClick: following ? unfollow : follow },
          { label: 'View Profile', icon: ProfileIcon, onClick: goToProfile }
        ]}/>
      )}
      { isCurrentUser && (
        <PopupMenu className="user-menu" triggeringRef={menuButtonRef} options={[
          { label: 'View Profile', icon: ProfileIcon, onClick: goHome }
        ]}/>
      )}
    </div>
  )
}

const FollowingSection = ({ editable, followings, loading, load, unload, userId }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [users, setUsers] = useState([])

  useEffect(() => {
    load()
    return () => {
      unload()
      setSearchTerm('')
    }
  }, [userId])
  
  useEffect(() => {
    setUsers(followings.filter((f) => f.name.toLowerCase().includes(searchTerm.toLowerCase()) || f.username.includes(searchTerm)))
  }, [searchTerm, followings])
  
  return (
    <div className="gear-container">
      <div className="profile-header-bar">
        <div className="profile-header">
          <h2>Followers</h2>
          { !loading && (
            <span className="count-badge">{ followings.length }</span>
          )}
        </div>
        <div className="search-container">
          <input className="search" type="text" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search"/>
          <span className="search-icon"><SearchIcon/></span>
        </div>
      </div>
      <div className={`followers-content profile-content ${!loading && users.length == 0 ? 'empty' : ''}`}>
        { loading && (
          <div className="loader-container" style={ { height: '200px' } }>
            <div className="loader"/>
          </div>
        )}
        { !loading && followings.length == 0 && (
          <div className="empty-content">
            <div className="icon"><FolderOffIcon/></div>
            <div>
              <h4>Not Following Anyone Yet!</h4>
              <p>Follow users so they can discover you!</p>
            </div>
            <div className="button button-primary" onClick={() => {}}>Explore Users</div>
          </div>
        )}
        { !loading && searchTerm.length > 0 && users.length == 0 && (
          <div className="empty-content">
            <div className="icon"><FolderOffIcon/></div>
            <div>
              <h4>No Users Found</h4>
              <p>Modify your search</p>
            </div>
          </div>
        )}
        { !loading && users.length > 0 && (
          <div className="users">
            { users.map((user, i) => {
              return (
                <SmallUserPanel user={user} key={i} index={i}/>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    editable: true, // state.user.profileUser.id == state.user.id
    loading: state.following.followingsLoading,
    followings: state.following.followings
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      dispatch(loadFollowings(ownProps.otherProfile))
    },
    unload: () => {
      dispatch(unloadFollowings())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowingSection)