const initialState = {
  modalOpen: false,
  modalSide: 'right',
  modal: null,
  closable: false,
  animate: false,
  toastTitle: '',
  toastMessage: ''
}

function modalReducer(state = initialState, action) {
  //console.log(action)
  switch(action.type) {
    case 'SET_MODAL':
      return {
        ...state,
        modal: action.modal,
        closable: action.closable
      }
    case 'UNSET_MODAL':
      return {
        ...state,
        modal: null
      }
    case 'OPEN_MODAL':
      return {
        ...state,
        modalOpen: true
      }
    case 'CLOSE_MODAL':
      return {
        ...state,
        modalOpen: false
      }
    case 'ANIMATE_MODAL':
      return {
        ...state,
        animate: true
      }
    case 'UNANIMATE_MODAL':
      return {
        ...state,
        animate: false
      }
    case 'POSITION_MODAL':
      return {
        ...state,
        modalSide: action.position
      }
    case 'SET_TOAST':
      return {
        ...state,
        toastTitle: action.title,
        toastMessage: action.message
      }
    default:
      return state;
  }
}

export default modalReducer