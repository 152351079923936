import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { openModal } from '../../actions/modalActions'
import HardDriveIcon from '../../images/hard_drive.svg'
import Diversity3Icon from '../../images/diversity_3.svg'
import SendIcon from '../../images/send.svg'
import QuoteIcon from '../../images/quote.svg'

const Homepage = ({ signup }) => {
  return (
    <div className="homepage-container">
      
      <div className="hero">
        <div className="hero-container">
          <div className="hero-outer-frame">
            <div className="hero-inner-frame-1">
              <h1>Gear Management System for Musicians</h1>
              <p>Manage &#x2022; Share  &#x2022; Explore</p>
            </div>
            <div className="hero-inner-frame-2">
              <div className="btn-primary">Get Started</div>
              <div className="btn-secondary">Learn More</div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="homepage-2">
        <div className="header-with-sub">
          <h2>Build, Save, & Share Your Signal Chain</h2>
          <p>The musician’s resource to catalog gear and signal chains</p>
        </div>
        <div className="signal-chain-image">
          <div className="btn-primary">Try Now</div>
          <div className="bolt"></div>
        </div>
      </div>
      
      <div className="black-container">
        <div className="bolt-accent-right"></div>
        <div className="homepage-3">
          <h2>Why Rigfish?</h2>
          <div className="two-column">
            <p className="large-text">If music gear is the center of your universe, including an unhealthy desire to share how you use your gear and care to check out how others use your gear, Rigfish is for you.</p>
            <p className="muted">Rigfish is your gear management platform to catalog your gear and signal chains. Check out who owns what gear and see how they use it in their signal chains. Learn from each other discovering new ways to use your gear and identify gear that complements your existing rig. Rigfish adds a social element to the platform so you can see who owns your gear and all the ways other players use your gear. Rigfish is the tool to identify gear that works with what you have because Rigfish connects users based on the gear you have. </p>
          </div>
          <div className="three-column">
            <div className="icon-box">
              <div className="icon"><HardDriveIcon/></div>
              <div className="icon-box-content">
                <h4>Store Your Gear & Signal Chains</h4>
                <p>Find and add all your gear to your personal collection you can start adding all your signal chains.</p>
              </div>
            </div>
            <div className="icon-box">
              <div className="icon"><Diversity3Icon/></div>
              <div className="icon-box-content">
                <h4>Learn From Other Players</h4>
                <p>Rigfish connects you to owners of your gear so you can see how they use it.</p>
              </div>
            </div>
            <div className="icon-box">
              <div className="icon"><SendIcon/></div>
              <div className="icon-box-content">
                <h4>Share With Your Networks</h4>
                <p>Share your gear and signal chains with your networks out side of Rigfish as you build out your collection..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="black-container">
        <div className="homepage-4">
          <div className="testimonial">
            <div className="quote-icon"><QuoteIcon/></div>
            <p className="testimonial-text">I’ve been waiting for something like this to launch for years! I really want to see how other players use the gear I own - or am interested in! It gives me new ideas on how to use my gear AND, more importantly, see if gear I’m interested in works with my stuff! Love it!</p>
            <div className="testimonial-author">
              <p className="testimonial-name">John Smith</p>
              <p className="testimonial-title">Guitar Player</p>
            </div>
            <div className="testimonial-paging">
              <div className="testimonial-page active"></div>
              <div className="testimonial-page"></div>
              <div className="testimonial-page"></div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    signup: () => dispatch(openModal('SIGNUP'))
  }
}
export default connect(null, mapDispatchToProps)(Homepage)