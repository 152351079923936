import React from "react"
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

const LoggedInModal = ({ firstName }) => {
  return (
    <div className="welcome-modal">
      <div className="message">
        <h2>Welcome back { firstName }</h2>
        <p>You have been logged in successfully</p>
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  firstName: state => state.user.firstName
})

export default connect(selector)(LoggedInModal);