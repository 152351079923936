import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import SearchIcon from '../images/search.svg'
import ExpandIcon from '../images/expand_more.svg'
import CheckIcon from '../images/check-icon.svg'
import TrashIcon from '../images/delete.svg'
import axios from 'axios'

// TODO:
// [ ] Infinite scroll
// [ ] Selecting an item
// [ ] hide type for pedals
// [ ] 

const gearSelection = ({ input, id, gearType, gearId, callback, multiple, meta: { touched, error, active } }) => {
  const collectionsList = {
    'instrument': ['All Instruments', 'My Instruments', 'Popular'],
    'pedal': ['All Pedals', 'My Pedals', 'Popular'],
    'amp': ['All Amps', 'My Amps', 'Popular'],
    'other': ['All Miscellaneous', 'My Gear', 'Popular']
  }
  const collections = collectionsList[gearType]
  const [collection, setCollection] = useState(collections[0])
  const [searchTerm, setSearchTerm] = useState('')
  const [options, setOptions] = useState({ categories: ['All'], types: ['All'], brands: ['All'] })
  const [category, setCategory] = useState('All')
  const [type, setType] = useState('All')
  const [brand, setBrand] = useState('All')

  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const [results, setResults] = useState([])
  const [triggerNewResults, setTriggerNewResults] = useState(false)
  const [selectedItem, setSelectedItem] = useState(undefined)
  const [selectedItems, setSelectedItems] = useState([])
  
  const [categoryFilterOpen, setCategoryFilterOpen] = useState(false)
  const [typeFilterOpen, setTypeFilterOpen] = useState(false)
  const [brandFilterOpen, setBrandFilterOpen] = useState(false)
  
  const didMount = useRef(false)
  const scrollRef = useRef()
  
  const MAX_RESULTS = 100
  
  const modalOpen = useSelector(state => state.modal.modalOpen)
  
  const resetAll = () => {
    setCategory('All')
    setType('All')
    setBrand('All')
    setSearchTerm('')
    setCollection(collections[0])
    setTriggerNewResults(!triggerNewResults)
  }
  
  useEffect(() => {
    if(modalOpen) { resetAll() }
  }, [modalOpen])
  
  const updateCategories = useEffect(() => {
    axios.get('/api/v2/gear_selection/category_options.json', { params: { gear_type: gearType } })
      .then((response) => { setOptions({...options, ...response.data}); })
      .then(() => didMount.current = true)
  }, [gearType])
  
  const updateTypes = useEffect(() => {
    if(didMount.current) {
      axios.get('/api/v2/gear_selection/type_options.json', { params: { gear_type: gearType, category: category } })
        .then((response) => { setOptions({...options, ...response.data}); setType('All') })
    }
  }, [category, didMount.current])
  
  const updateBrands = useEffect(() => {
    if(didMount.current) {
      axios.get('/api/v2/gear_selection/brand_options.json', { params: { gear_type: gearType, category: category, type: type } })
        .then((response) => { setOptions({...options, ...response.data}); setBrand('All') })
    }
  }, [type, category, didMount.current])
  
  const fetchResults = (p) => {
    if(loading) { return }
    setLoading(true)
    console.log("fetching results for page", p)
    axios.get('/api/v2/gear_selection/search.json', { params: { collection: collection, gear_type: gearType, category: category, type: type, brand: brand, search_term: searchTerm, page: p } })
      .then((response) => { 
        if(response.data.length == MAX_RESULTS) {
          setHasNextPage(true)
        }
        else {
          setHasNextPage(false)
        }
        setResults(items => [...items, ...response.data]) 
        setLoading(false)
      })
  }
  
  const getNewResults = useEffect(() => {
    console.log("getNewResults, setting page to 1")
    setPage(1)
    scrollRef.current.scrollTop = 0
    setResults([])
    setSelectedItem(undefined)
    fetchResults(1);
  }, [category, type, brand, searchTerm, triggerNewResults, collection])
  
  
  // TODO: make one endpoint for retrieving all fresh options
  const resetForNewGearId = useEffect(() => {
    // setResults([])
    // axios.get('/api/v2/gear_selection/category_options.json', { params: { gear_type: gearType } })
    //   .then((response) => { 
    //     setOptions({...options, ...response.data})
    //     axios.get('/api/v2/gear_selection/type_options.json', { params: { gear_type: gearType, category: 'All' } })
    //       .then((response) => { 
    //         setOptions({...options, ...response.data}) 
    //         axios.get('/api/v2/gear_selection/brand_options.json', { params: { gear_type: gearType, category: 'All', type: 'All' } })
    //           .then((response) => { 
    //             setOptions({...options, ...response.data}) 
    //             setCategory('All')
    //             setType('All')
    //             setBrand('All')
    //             setSearchTerm('')
    //             setTriggerNewResults(!triggerNewResults)
    //           })
    //       })
    //   })
  }, [gearType, gearId])
  
  const handleScroll = (e) => {
    if(hasNextPage && !loading && e.target.scrollTop + e.target.offsetHeight > e.target.scrollHeight*.9) {
      scrollRef.current.removeEventListener("scroll", handleScroll)
      fetchResults(page + 1)
      setPage(p => p+1)
    }
  }
  
  // register scroll handler
  useEffect(() => {
    const div = scrollRef.current
    div.addEventListener("scroll", handleScroll)
    return () => div.removeEventListener("scroll", handleScroll)
  }, [hasNextPage, loading])
  
  // update selection
  useEffect(() => {
    if(multiple) {
      input.onChange(selectedItems.map((g) => g.id))
    }
    else {
      input.onChange(selectedItem)
    }
  }, [selectedItem, selectedItems])
  
  useEffect(() => {
    if(!input.value) {
      setSelectedItem(undefined)
    }
  }, [input.value])
  
  return (
    <div className="gear-selection-panel">
      <div className="selection-panel">
        <div className="categories">
          { collections.map((c, i) => {
            return (
              <span className={c === collection ? "pill active" : "pill"} key={i} onClick={ (e) => { setCollection(c) } }>{c}</span>
            )
            })
          }
        </div>
        <div className="search-container">
          <input className="search" type="text" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search"/>
          <span className="search-icon"><SearchIcon/></span>
        </div>
        <div className="filters-container">
          <div className={ categoryFilterOpen ? "filters-button active" : "filters-button" } onClick={ (e) => { setCategoryFilterOpen(!categoryFilterOpen); setTypeFilterOpen(false); setBrandFilterOpen(false); } }>
            {category != 'All' ? category : "Category"}<ExpandIcon/>
            <div className="filter-dropdown">
              <h6>Select Category</h6>
              <div className="options">
                { options.categories.map((c, i) => {
                    return <div className={c === category ? "option active" : "option"} key={i} onClick={() => setCategory(c)}>{c}</div>              
                  })
                }
              </div>
            </div>
          </div>
          { gearType == 'instrument' && (
            <div className={ typeFilterOpen ? "filters-button active" : "filters-button" } onClick={ (e) => { setCategoryFilterOpen(false); setTypeFilterOpen(!typeFilterOpen); setBrandFilterOpen(false); } }>
              {type != 'All' ? type : "Type"}<ExpandIcon/>
              <div className="filter-dropdown">
                <h6>Select Type</h6>
                <div className="options">
                  { options.types.map((t, i) => {
                      return <div className={t === type ? "option active" : "option"} key={i} onClick={() => setType(t)}>{t}</div>              
                    })
                  }
                </div>
              </div>
            </div>
          )}
          <div className={ brandFilterOpen ? "filters-button active" : "filters-button" } onClick={ (e) => { setCategoryFilterOpen(false); setTypeFilterOpen(false); setBrandFilterOpen(!brandFilterOpen); } }>
            {brand != 'All' ? brand : "Brand"}<ExpandIcon/>
            <div className="filter-dropdown">
              <h6>Select Brand</h6>
              <div className="options">
                { options.brands.map((b, i) => {
                    return <div className={b === brand ? "option active" : "option"} key={i} onClick={() => setBrand(b)}>{b}</div>              
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="results" ref={scrollRef}>
          { results.map((r, i) => {
            return (
              <div className="gear-card" key={i} onClick={() => {
                  if(!multiple) {
                    if(selectedItem == r.id) {
                      setSelectedItem(undefined)
                    }
                    else {
                      setSelectedItem(r.id)
                    }
                  }
                  else {
                    setSelectedItems([...selectedItems, {id: r.id, image: r.image, name: r.name}])
                  }
                }
              }>
                <div className="img-card"><img src={r.image}/>{ (selectedItem == r.id) && <span className="item-selected"><CheckIcon/></span>}</div>
                <p>{r.name}</p>
              </div>
            )          
          })
          }
        </div>
      </div>
      { multiple && (
        <div className="gear-selected-panel">
          <div className="gear-selected-header">
            {selectedItems.length} Selected Items
          </div>
          <div className="gear-selected-results">
            { selectedItems.map((g,i) => {
              return (
                <div className="selected-gear" key={i}>
                  <div className="selected-gear-details">
                    <div className="image"><img src={g.image}/></div>
                    <div className="name">{g.name}</div>
                  </div>
                  <div className="trash" onClick={ () => { const newArray = Array.from(selectedItems); newArray.splice(i, 1); setSelectedItems(newArray) } }><TrashIcon/></div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default gearSelection