import React from "react"
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

const ToastModal = ({ title, message }) => {
  return (
    <div className="toast-modal">
      <div className="message">
        <h2>{ title }</h2>
        { message && (
          <p>{message}</p>
        )}
      </div>
    </div>
  )
}

const selector = createStructuredSelector({
  title: state => state.modal.toastTitle,
  message: state => state.modal.toastMessage
})

export default connect(selector)(ToastModal);