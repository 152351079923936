import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { useParams } from 'react-router-dom'
import { fetchSignalChain, openSelectGearModal, insertNewGear, removeGear, openSignalChainDetailsModal } from '../../actions/signalChainActions'
import { orderedSignalChainGear } from '../../utils/signalChainUtils'
import ConnectionLine from '../../images/connection_line.svg'
import AddIcon from '../../images/add_gear.svg'
import TrashIcon from '../../images/trash.svg'
import UserCountIcon from '../../images/person_yellow.svg'
import SignalChainCountIcon from '../../images/restart_alt_pink.svg'

let GearItem = ({ gear, selectGear, removeGear }) => {
  return (
    <div className="gear-item">
      <div className="image">
        { gear && gear.gear_item ? (
          <img src={gear.gear_item.image_src}/>
          ) : (
          <div className="placeholder"/>
        )}
      </div>
      <div className="info">
        <div className="name">
          { gear && gear.gear_item && gear.gear_item.name ? (
            gear.gear_item.name
            ) : (
            gear.gear_type.charAt(0).toUpperCase() + gear.gear_type.slice(1)
          )}
          </div>
          
        { gear && gear.gear_item && (
          <div className="stats">
            <div className="user-count"><UserCountIcon/>{  gear && gear.gear_item && gear.gear_item.user_count } users own this</div>
            <div className="signal-chain-count"><SignalChainCountIcon/>{  gear && gear.gear_item && gear.gear_item.signal_chain_count } times used in signal chain</div>
          </div>
        )}
        <div className="select-gear-button-container">
          <div className="select-gear-button" onClick={ selectGear }>{ gear.gear_item ? "Change" : "Select"} { gear.gear_type.charAt(0).toUpperCase() + gear.gear_type.slice(1) }</div>
        </div>
      </div>
      <div className="control-overlay">
        { (gear.gear_type == 'pedal' || gear.gear_type == 'amp') && (
          <div className="remove" onClick={ removeGear }>
            <TrashIcon/>
          </div>
        )}
      </div>
    </div>
  )
}

const gearStateToProps = (state, ownProps) => {
  return {}
}

const gearDispatchToProps = (dispatch, ownProps) => {
  return {
    selectGear: () => { dispatch(openSelectGearModal(ownProps.gear, false)) },
    removeGear: () => { dispatch(removeGear(ownProps.gear)) }
  }
}

GearItem = connect(gearStateToProps, gearDispatchToProps)(GearItem)

let Connection = (({connection, addPedals, addAmps, addGear}) => {
  return (
    <div className="connection">
      <ConnectionLine/>
      <div className="add-button">
        <AddIcon/>
        <div className="add-buttons">
          <div className="add-multiple-pedals" onClick={ addPedals }>Add Pedals</div>
          <div className="add-single-pedal" onClick={ addGear }><AddIcon/></div>
          <div className="add-multiple-amps" onClick={ addAmps }>Add Amps</div>
        </div>
      </div>
    </div>
  )
})

const connectionStateToProps = (state, ownProps) => {
  return {}
}

const connectionDispatchToProps = (dispatch, ownProps) => {
  return {
    addGear: () => { dispatch(insertNewGear(ownProps.connection)) },
    addPedals: () => { dispatch(openSelectGearModal(ownProps.connection, true, 'pedal')) },
    addAmps: () => { dispatch(openSelectGearModal(ownProps.connection, true, 'amp')) }
  }
}

Connection = connect(connectionStateToProps, connectionDispatchToProps)(Connection)

const scroll = (e) => { if(document.getElementById('canvas-container')) { document.getElementById('canvas-container').scrollLeft += e.deltaY } }

const SignalChainBuilder = ({ retrieveSignalChain, signalChain, modalOpen, openDetailsModal }) => {
  let { id } = useParams()
  
  useEffect(() => {
    retrieveSignalChain(id)
  }, [id])

  useEffect(() => {
    let canvas = document.getElementById('canvas-container')
    if(canvas && !modalOpen) {
      //console.log("adding scroll handler")
      window.addEventListener("wheel", scroll)
    }
    else {
      //console.log("removing scroll handler")
      window.removeEventListener("wheel", scroll)
    }
  }, [modalOpen])
  
  const orderedGear = signalChain && orderedSignalChainGear(signalChain) 
  
  return (
    <div className="signal-chain-builder">
      <div className="title">
        <h2>{signalChain && signalChain.name}</h2>
      </div>
      <div className="canvas-outer-container" id="canvas-container">
        <div className="canvas-inner-container">
          <div className="canvas">
            { signalChain && orderedGear.map((g, i) => {
                return g.type == 'gear' ? (
                  <GearItem gear={g} key={i}/>
                  ) : (
                  <Connection connection={g} key={i}/>
                )
              })
            }
            { orderedGear && <Connection connection={{ id: undefined, from: orderedGear[orderedGear.length-1].id, to: undefined}}/> }
          </div>
        </div>
      </div>
      <div className="save-button button button-primary" onClick={ openDetailsModal }>Edit Details</div>
    </div>
  )
}

const mapBuilderStateToProps = (state, ownProps) => {
  return {
    signalChain: state.signalChain.signalChain,
    modalOpen: state.modal.modalOpen
  }
}

const mapBuilderDispatchToProps = (dispatch, ownProps) => {
  return {
    retrieveSignalChain: (id) => dispatch(fetchSignalChain(id)),
    openDetailsModal: () => dispatch(openSignalChainDetailsModal())
  }
}

export default connect(mapBuilderStateToProps, mapBuilderDispatchToProps)(SignalChainBuilder)