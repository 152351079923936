const initialState = {
  totalCount: 0,
  instruments: [],
  pedals: [],
  amps: [],
  other: [],
  plugins: [],
  activeUsersGear: undefined,
  loading: true,
  editable: true,
}

const gearReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_GEAR_LOADING': {
      return {
        ...state,
        loading: action.loading
      }
    }
    case 'LOAD_ALL_GEAR':
      console.log("loading all gear")
      const instruments = action.data.filter((ug) => ug.gear.gear_type == 'instrument').sort((a, b) => a.gear.name.toLowerCase().localeCompare(b.gear.name.toLowerCase()))
      const pedals = action.data.filter((ug) => ug.gear.gear_type == 'pedal').sort((a, b) => a.gear.name.toLowerCase().localeCompare(b.gear.name.toLowerCase()))
      const amps = action.data.filter((ug) => ug.gear.gear_type == 'amp').sort((a, b) => a.gear.name.toLowerCase().localeCompare(b.gear.name.toLowerCase()))
      const other = action.data.filter((ug) => ug.gear.gear_type == 'other').sort((a, b) => a.gear.name.toLowerCase().localeCompare(b.gear.name.toLowerCase()))
      console.log("instruments", instruments)
      console.log("pedals", pedals)
      console.log("amps", amps)
      console.log("other", other)
      return {
        ...state,
        totalCount: action.data.length,
        instruments: instruments,//action.data.instruments,
        pedals: pedals,//action.data.instruments,
        amps: amps,//action.data.instruments,
        other: other,
        plugins: [],
        loading: false,
        editable: action.editable
      }
    case 'UNLOAD_ALL_GEAR':
      return initialState
    case 'SET_ACTIVE_USERS_GEAR': 
      return {
        ...state,
        activeUsersGear: action.data
      }
    default:
      return state
  }
}

export default gearReducer