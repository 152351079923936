import React, { useState } from "react"

const passwordField = ({ input, label, type, meta: { touched, error } }) => {
  let [hidden, setHidden] = useState(true)
  
  return (
  <div className="form-group">
    <label>{label}</label>
    <input {...input} placeholder={label} type={hidden ? 'password' : 'text'}/>
    <span className="material-symbols-outlined password-visibility-icon" onClick={() => { setHidden(!hidden) } }>{ hidden ? 'visibility' : 'visibility_off'}</span>
    {touched && error && <span className="error">{error}</span>}
  </div>
  )
}


export { passwordField }