import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const dateField = ({ input, label, meta: { touched, error, active } }) => {
  
  useEffect(() => {
    console.log(input.value, new Date(input.value))
  }, [input.value])
  
  return (
    <div className="form-group datepicker">
      <label>{label}</label>
      <DatePicker dateFormat="MM/dd/yyyy" selected={input.value && new Date(input.value)} onChange={input.onChange}/>
    </div>
  )
} 

export { dateField }