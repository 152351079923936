import React from 'react'
import { useMatch } from 'react-router-dom'
import logo from '../images/logo.png'
import FacebookIcon from '../images/facebook.svg'
import TwitterIcon from '../images/twitter.svg'
import InstagramIcon from '../images/instagram.svg'

const Footer = () => {
  // don't put footer on single page pages like the signal chain builder
  const footerMatch = !useMatch('/signal_chains/*')
  
  return (
    <>
      { footerMatch && (
        <div className="footer-container">
          <div className="footer">
            <div className="footer-column-1">
              <div className="rigfish-description">
                <div className="logo">
                  <img src={logo}/>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non commodo leo. Maecenas eu augue quis sem accumsan lacinia. Etiam nulla massa, semper quis sapien non.</p>
              </div>
              <div className="social-icons">
                <div className="social-icon"><FacebookIcon/></div>
                <div className="social-icon"><TwitterIcon/></div>
                <div className="social-icon"><InstagramIcon/></div>
              </div>
            </div>
            <div className="footer-columns-234-grouping">
              <div className="footer-column-2">
                <h3>Lorem Ipsum</h3>
                <a>Sample Link</a>
                <a>Sample Link</a>
                <a>Sample Link</a>
                <a>Sample Link</a>
                <a>Sample Link</a>
              </div>
              <div className="footer-column-3">
                <h3>Lorem Ipsum</h3>
                <a>Sample Link</a>
                <a>Sample Link</a>
                <a>Sample Link</a>
                <a>Sample Link</a>
                <a>Sample Link</a>
               </div>
              <div className="footer-column-4">
                <h3>Company</h3>
                <a>About</a>
                <a>Contact</a>
                <a>Help</a>
                <a>Terms of Use</a>
                <a>Privacy Policy</a>
              </div>
            </div>
          </div>
          <div className="copyright-container">
            <p>&copy; 2023 Rigfish All rights reserved</p>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer