import React, { useState, useRef } from "react"
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { NavLink, useMatch } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { createStructuredSelector } from 'reselect'
import logo from '../images/logo.png'
import { openModal } from '../actions/modalActions'
import { logout } from '../actions/userActions'
import PopupMenu from './controls/PopupMenu'

import WindowIcon from '../images/window.svg'
import TravelExploreIcon from '../images/travel_explore.svg'
import AccountCircleIcon from '../images/account_circle.svg'
import AddIcon from '../images/add.svg'
import NotificationIcon from '../images/notifications.svg'
import ExpandIcon from '../images/expand_more.svg'
import SettingsIcon from '../images/settings.svg'
import LogoutIcon from '../images/move_item.svg'
import defaultProfileImage from '../images/default-profile-image.jpg'

// Use Link from React Router DOM 

const Header = ({ loggedIn, login, logout, signup, newSignalChain, username, profileImageUrl }) => {
  let [accountMenuOpen, setAccountMenuOpen] = useState(false)
  let navigate = useNavigate()
  const profileMatch = useMatch('/profile/*')
  const openMenuRef = useRef()
  
  if(loggedIn) {
    return (
      <section className="base-header" id="header">
        <div className="logo">
          <NavLink to={`/`}><img src={logo}/></NavLink>
        </div>
        <nav className="main-navigation">
          <ul>
            <li><NavLink to={`/feeds`}><span className="icon"><WindowIcon/></span>Feeds</NavLink></li> 
            <li><NavLink to={`/explore`}><span className="icon"><TravelExploreIcon/></span>Explore</NavLink></li> 
            <li><NavLink to={`/profile/gear`} className={ profileMatch ? 'active' : undefined }><span className="icon"><AccountCircleIcon/></span>Profile</NavLink></li> 
            <li><div onClick={ () => newSignalChain() }><span className="icon"><AddIcon/></span>New Signal Chain</div></li> 
          </ul>
        </nav>
        <nav className="account-navigation">
          <div className="notifications">
            <NotificationIcon/>
            <div className="badge active"></div>
          </div>
          <a className="account-button" ref={openMenuRef}>
            <span className="profile-image"><img src={profileImageUrl || defaultProfileImage}/></span>
            <span className="username">{ username }</span>
            <ExpandIcon/>
          </a>
          
          <PopupMenu triggeringRef={openMenuRef} className="account-menu" options={
            [
              { label: 'Account Settings', icon: SettingsIcon, onClick: () => navigate('/settings/personal') },
              { label: 'Logout', icon: LogoutIcon, onClick: () => { logout(navigate); } },
            ]
          }/>
        </nav>
      </section>
    )
  }
  else {
    return (
      <section className="base-header" id="header">
        <div className="logo">
          <NavLink to={`/`}><img src={logo}/></NavLink>
        </div>
        <nav className="base-navigation">
          <ul>
            <li><NavLink to={`/features`}>Features</NavLink></li> 
            <li><NavLink to={`/about`}>About</NavLink></li> 
            <li><NavLink to={`/faqs`}>FAQs</NavLink></li> 
            <li><NavLink to={`/contact`}>Contact</NavLink></li> 
            <li><NavLink to={`/help`}>Help</NavLink></li> 
          </ul>
        </nav>
        <nav className="login-navigation">
          <ul>
            <li><a className="btn btn-primary" onClick={signup}>Signup</a></li>
            <li><a className="btn btn-secondary" onClick={login}>Login</a></li>
          </ul>
        </nav>
      </section>
    )
  }
}

const selector = createStructuredSelector({
  loggedIn: state => state.user.loggedIn,
  username: state => state.user.username,
  profileImageUrl: state => state.user.currentUser.avatar && state.user.currentUser.avatar.length > 0 && state.user.currentUser.avatar[0].url
})

 
const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(openModal('LOGIN')),
    signup: () => dispatch(openModal('SIGNUP')),
    newSignalChain: () => { dispatch(reset('new_signal_chain')); dispatch(openModal('NEW_SIGNAL_CHAIN')) },
    logout: (navigate) => dispatch(logout(navigate))
  }
}

export default connect(selector, mapDispatchToProps)(Header);