import React from "react"
import axios from 'axios'
import { BrowserRouter, Routes, Route, useMatch, Navigate } from 'react-router-dom'
import { connect, Provider, useSelector } from 'react-redux'
import { useConstructor } from '../utils/customHooks'
import configureStore from '../reducers/configureStore'
import Header from './Header'
import Footer from './Footer'
import Modal from './Modal'
import Homepage from './pages/Homepage'
import Profile from './pages/Profile'
import Explore from './pages/Explore'
import AccountSettings from './pages/AccountSettings'
import SignalChainBuilder from './pages/SignalChainBuilder'

const store = configureStore()

const App = ({ initialProps }) => {
  
  useConstructor(() => {
    console.log("initial props", initialProps)
    if(initialProps.currentUser) {
      store.dispatch({ type: 'SET_INITIAL_USER_DATA', data: initialProps.currentUser })
    }
    store.dispatch({ type: 'SET_FORM_DATA', data: initialProps.formData })
  })
  
  return (
    <Provider store={store}>
      <AppWithProvider/>
    </Provider>
  )
  
}

const AppWithProvider = () => {
  
  const user = useSelector((state) => state.user)
  
  return (
    <BrowserRouter>
      <Header/>
      <div className="page-content">
        <Routes>
          <Route exact path="/" element={ <Homepage/> }/>
          { (user && user.id) ? (
            <React.Fragment>
              <Route path="/profile/*" element={ <Profile myProfile={true} /> }/>
              <Route path="/explore" element={ <Explore/> }/>
              <Route path="/users/:userId/profile/*" element={ <Profile myProfile={false}/> }/>
              <Route path="/settings/*" element={ <AccountSettings/> }/>
              <Route path="/signal_chains/:id" element={ <SignalChainBuilder/> }/>
              <Route path="*" element={ <h1>Not Found</h1> }/>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route path="/profile/*" element={ <Navigate to="/"/> }/>
              <Route path="/explore" element={ <Navigate to="/"/> }/>
              <Route path="/users/*" element={ <Navigate to="/"/> }/>
              <Route path="/settings/*" element={ <Navigate to="/"/> }/>
              <Route path="/signal_chains/:id" element={ <Navigate to="/"/> }/>
              <Route path="*" element={ <h1>Not Found</h1> }/>
            </React.Fragment>
          )}
        </Routes>
      </div>
      <Footer/>
      <Modal/>
    </BrowserRouter>
  )
}

export default App
