import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchExploreUsers, fetchExploreSignalChains } from '../../actions/exploreActions'
import { followUser, unfollowUser } from '../../actions/followingActions'

import ExploreArrow from '../../images/explore-arrow.svg'
import defaultProfileImage from '../../images/default-profile-image.jpg'

let User = ({id, username, bio, profile_image, genres, followingIds, signal_chain_count, gear_count}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const follow = () => {
    dispatch(followUser(id))
  }

  const unfollow = () => {
    dispatch(unfollowUser(id))
  }
  
  const viewProfile = () => {
    navigate(`/users/${id}/profile`)
  }

  return (
    <div className="user">
      <div className="profile-image">
        <img className="profile" src={ (profile_image && profile_image.url) || defaultProfileImage }/>
      </div>
      <div className="info-container">
        <h3>{username}</h3>
        <p style={{marginBottom: '8px'}}>{bio}</p>
        { genres.length > 0 && (
        <h4>Music Styles</h4>
        )}
        { genres.length > 0 && (
        <div className="genres pill-list">
          { genres.map((genre, i) => {
            return (
              <span className="pill" key={i}>{genre.name}</span>
            )
          })}
        </div>
        )}
      </div>
      <div className="stats-container">
        <h4>Signal Chains Created</h4>
        <p>{signal_chain_count}</p>
        <h4>Gear in Collection</h4>
        <p>{gear_count}</p>
      </div>
      <div className="controls-container">
        { followingIds.includes(id) ? (
          <div className="button button-tertiary" onClick={unfollow}>Unfollow</div>
        ) : (  
          <div className="button button-tertiary" onClick={follow}>Follow</div>
        )}
        <div className="button button-tertiary" onClick={viewProfile}>View Profile</div>
      </div>
    </div>
  )
}

User = connect((state) => {
  return {
    followingIds: state.user.currentUser.followingIds
  }
}, {})(User)

const ExploreUsers = ({users}) => {
  return (
    <div className="users-container">
      { users.map((user, i) => {
        return (
          <User {...user} key={i}/>
        )
      })}
    </div>
  )
}

const Explore = ({loadUsers, loadSignalChains, loading, users, signalChains}) => {
  const [mode, setMode] = useState('users')
  
  useEffect(() => {
    if(mode == 'users') {
      loadUsers()
    }
    else if(mode == 'signalChains') {
      loadSignalChains()
    }
  }, [mode])

  return (
    <div className="explore-page-container">
      <h2 className="title">Explore Rigfish</h2>
      <ExploreArrow/>
      <div style={{width: '1px', height: '24px'}}/>
      { loading && (
        <div className="loader-container" style={ { height: '500px' } }>
          <div className="loader"/>
        </div>
      )}
      {
        !loading && mode == 'users' && (
          <ExploreUsers users={users} />
        )
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadUsers: () => dispatch(fetchExploreUsers()),
    loadSignalChains: () => dispatch(fetchExploreSignalChains())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.explore.loading,
    users: state.explore.users,
    signalChains: state.explore.signalChains
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explore)

