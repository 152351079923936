const initialState = {
  signalChain: null,
  signalChains: [],
  signalChainsLoading: false,
  editable: false,
  processing: false,
  interfaceMode: 'select',
  interfaceX: 0,
  interfaceY: 0,
  modalGearType: 'instrument',
  modalGearId: undefined,
  modalConnectionId: undefined,
  modalKey: 0
}

function signalChainReducer(state = initialState, action) {

  switch(action.type) {
    case 'SET_SIGNAL_CHAIN':
      console.log('Setting signal chain', action.data)
      return {
        ...state,
        signalChain: action.data
      }
    case 'SET_SIGNAL_CHAINS':
      return {
        ...state,
        signalChains: action.data,
        signalChainsLoading: false,
        editable: action.editable
      }
    case 'UNSET_SIGNAL_CHAINS':
      return {
        ...state,
        signalChains: [],
        editable: false
      }
    case 'SET_SIGNAL_CHAINS_LOADING':
      return {
        ...state,
        signalChainsLoading: action.loading
      }
    case 'SET_GEAR_SELECTION_PARAMS_SINGLE':
      return {
        ...state,
        modalGearType: action.gear.gear_type,
        modalGearId: action.gear.id,
        modalKey: state.modalKey + 1,
        multipleInput: false
      }
    case 'SET_GEAR_SELECTION_PARAMS_MULTIPLE':
      return {
        ...state,
        modalGearType: action.gearType,
        modalConnectionId: action.connection.id,
        modalGearId: action.connection.from,
        modalKey: state.modalKey + 1,
        multipleInput: true
      }
    case 'SET_SIGNAL_CHAIN_PROCESSING':
      return {
        ...state,
        processing: action.processing
      }
    default:
      return state
  }
}

export default signalChainReducer