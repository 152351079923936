import axios from 'axios'
import { closeModal, openModal, transitionModalRight } from './modalActions'
import { waitFor } from '../utils/promises'
import { reset } from 'redux-form'
const { updateSyncErrors } = require('redux-form/lib/actions').default;

const loadSignalChains = (userId = null) => {
  return (dispatch, getState) => {
    dispatch({type: 'SET_SIGNAL_CHAINS_LOADING', loading: true})
    dispatch(fetchSignalChains(userId))
  }
}

const fetchSignalChains = (userId = null) => {
  return (dispatch, getState) => {
    console.log("user id", userId)
    if(!userId) {
      userId = getState().user.id
      if(!userId) {
        return
      }
    }
    const url = `/api/v2/users/${userId}/signal_chains.json`
    axios.get(url)
      .then((response) => {
        console.log("signal chains response data: ", response.data)
        dispatch({type: 'SET_SIGNAL_CHAINS', data: response.data, editable: true})
      })
      .catch((error) => {
        dispatch({type: 'SET_SIGNAL_CHAINS_LOADING', loading: false})
      })
  }
}

const unloadSignalChains = () => {
  return (dispatch, getState) => {
    dispatch({type: 'UNSET_SIGNAL_CHAINS'})    
  }
}


function createNewSignalChain(formValues, navigate) {
  return (dispatch, getState) => {
    axios.post('/api/v2/signal_chains.json', formValues)
      .then(response => {
        const data = response.data
        if(data.errors) {
          dispatch(updateSyncErrors('new_signal_chain', {
            signal_chain: {
              ...data.errors
            }
          }))
        }
        else {
          dispatch({type: 'SET_SIGNAL_CHAIN', data: data })
          closeModal()(dispatch, getState)
          navigate(`/signal_chains/${data.id}`)
          //waitFor(1000).then(() => reset('new_signal_chain'))
        }
      })
      .catch(error => dispatch({type: 'SIGNAL_CHAIN_CREATION_FAILURE'}))
  }
}

function fetchSignalChain(id, callback = null) {
  return (dispatch, getState) => {
    axios.get(`/api/v2/signal_chains/${id}.json`)
      .then(response => {
        dispatch({type: 'SET_SIGNAL_CHAIN', data: response.data })
        if(callback) { callback() }
      })
  }
}

function openSelectGearModal(gearOrConnection, multiple, gearType="pedal") {
  return (dispatch, getState) => {
    if(multiple) {
      dispatch({type: 'SET_GEAR_SELECTION_PARAMS_MULTIPLE', connection: gearOrConnection, gearType: gearType})
    }
    else {
      dispatch({type: 'SET_GEAR_SELECTION_PARAMS_SINGLE', gear: gearOrConnection})
    }
    openModal('SELECT_GEAR')(dispatch, getState)
  }
}

function submitGearSelection(params) {
  return (dispatch, getState) => {
    if(!getState().signalChain.processing) {
      if(params.signal_chain.gear_ids) {
        const isMultiple = params.signal_chain.gear_ids.length > 1
        let signalChainParams = params.signal_chain
        if(!isMultiple) {
          signalChainParams.gear_ids = [signalChainParams.gear_ids] // here because the form element returns a single value for single inputs and an array for multiples, and rails expects just an array
        }
        params = { signal_chain: { ...signalChainParams, action: 'select_gear', multiple: isMultiple } }
        console.log("submitting gear selection", params)
        dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: true})
        axios.put(`/api/v2/signal_chains/${getState().signalChain.signalChain.id}.json`, params)
          .then(response => {
            dispatch({type: 'SET_SIGNAL_CHAIN', data: response.data })
            dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: false})
            closeModal()(dispatch, getState)
          })
          .catch(() => {
            dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: false})
          })
      }
      else {
        closeModal()(dispatch, getState)
      }
    }
  }
}

// adds a blank gear
function insertNewGear(connection) {
  return (dispatch, getState) => {
    if(!getState().signalChain.processing) {
      let params = { signal_chain: { action: 'add_blank_gear', connection_id: connection.connectionId, from_gear_id: connection.from } }
      console.log("inserting new gear at connection", connection.connectionId, connection.from)
      dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: true})
      axios.put(`/api/v2/signal_chains/${getState().signalChain.signalChain.id}.json`, params)
        .then(response => {
          dispatch({type: 'SET_SIGNAL_CHAIN', data: response.data })
          dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: false})
        })
        .catch(() => {
          dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: false})
        })
    }
  }
}

function removeGear(gear) {
  return (dispatch, getState) => {
    if(!getState().signalChain.processing) {
      let params = { signal_chain: { action: 'remove_gear', scg_id: gear.id } }
      console.log("removing gear", params)
      dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: true})
      axios.put(`/api/v2/signal_chains/${getState().signalChain.signalChain.id}.json`, params)
        .then(response => {
          dispatch({type: 'SET_SIGNAL_CHAIN', data: response.data })
          dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: false})
        })
        .catch(() => {
          dispatch({type: 'SET_SIGNAL_CHAIN_PROCESSING', processing: false})
        })
    }
  }
}

const setSignalChain = (signalChain) => {
  return (dispatch, setState) => {
    dispatch({type: 'SET_SIGNAL_CHAIN', data: signalChain})
  }
}

function openSignalChainDetailsModal(signalChain = null) {
  return (dispatch, getState) => {
    if(signalChain) {
      dispatch(fetchSignalChain(signalChain.id))
    }
    dispatch(reset('signal_chain'))
    openModal('SIGNAL_CHAIN_DETAILS')(dispatch, getState)
  }
}

const openSignalChainInfoModal = (signalChain) => {
  return (dispatch, getState) => {
    if(signalChain) {
      dispatch(fetchSignalChain(signalChain.id, () => { openModal('SIGNAL_CHAIN_INFO')(dispatch, getState) }))
    }
  }
}

function saveSignalChainDetails(params) {
  return (dispatch, getState) => {
    console.log("signal_chain params", params)

    const id = params.signal_chain.id

    const formData = new FormData()

    formData.append('signal_chain[action]', 'update_details')
    formData.append('signal_chain[name]', params.signal_chain.name)
    formData.append('signal_chain[signal_chain_type]', params.signal_chain.signal_chain_type)
    formData.append('signal_chain[genre_id]', params.signal_chain.genre_id)
    formData.append('signal_chain[daw_id]', params.signal_chain.daw_id)
    formData.append('signal_chain[venue]', params.signal_chain.venue || "")
    formData.append('signal_chain[description]', params.signal_chain.description)

    if(params.signal_chain.reference_links) {
      params.signal_chain.reference_links.forEach((link, index) => {
        formData.append(`signal_chain[reference_links_attributes][${index}][title]`, link.title)
        formData.append(`signal_chain[reference_links_attributes][${index}][link]`, link.link)
      })
    }

    params.signal_chain.images.forEach((image, index) => {
      formData.append(`signal_chain[images_attributes][${index}][id]`, image.id)
      if(image.file) {
        formData.append(`signal_chain[images_attributes][${index}][file]`, image.file)
      }
      else {
        formData.append(`signal_chain[images_attributes][${index}][url]`, image.url)
      }
    })

    params.signal_chain.audio_clips.forEach((clip, index) => {
      formData.append(`signal_chain[audio_clips_attributes][${index}][id]`, clip.id)
      formData.append(`signal_chain[audio_clips_attributes][${index}][name]`, clip.name)
      if(clip.file) {
        formData.append(`signal_chain[audio_clips_attributes][${index}][file]`, clip.file, clip.name)
      }
      else {
        formData.append(`signal_chain[audio_clips_attributes][${index}][url]`, clip.url)
      }
    })

    axios.put(`/api/v2/signal_chains/${id}.json`, formData)
      .then(response => {
        const data = response.data
        if(data.errors) {
          dispatch(updateSyncErrors('signal_chain', {
            signal_chain: {
              ...data.errors
            }
          }))
        }
        else {
          dispatch({type: 'SET_SIGNAL_CHAIN', data: data })
          dispatch(fetchSignalChains())
          closeModal()(dispatch, getState)
        }
      })
      .catch(error => dispatch({type: 'SIGNAL_CHAIN_SAVE_FAILURE'}))
  }
}

const deleteSignalChain = (signalChain) => {
  return (dispatch, getState) => {
    console.log("Deleting Signal Chain with id", signalChain.id)
    axios.delete(`/api/v2/signal_chains/${signalChain.id}.json`)
      .then((response) => {
        dispatch(fetchSignalChains())
      })
      .catch((error) => {
      })
  }
}


export { createNewSignalChain, 
         fetchSignalChain, 
         openSelectGearModal, 
         submitGearSelection, 
         insertNewGear, 
         removeGear, 
         openSignalChainDetailsModal, 
         openSignalChainInfoModal,
         saveSignalChainDetails, 
         loadSignalChains, 
         unloadSignalChains, 
         deleteSignalChain }