import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadGear, unloadGear, addGear, removeGear, openGearDetailsModal, openGearInfoModal } from '../../../actions/gearActions'
import { capitalizeEachWord } from '../../../utils/documentUtils'
import PopupMenu from '../../../components/controls/PopupMenu'

import FolderOffIcon from '../../../images/folder_off.svg'
import ArrowForwardIcon from '../../../images/arrow_forward.svg'
import MenuIcon from '../../../images/more_vert.svg'
import EditIcon from '../../../images/edit.svg'
import InfoIcon from '../../../images/info.svg'
import TrashIcon from '../../../images/trash.svg'
import ReverbIcon from '../../../images/reverb_icon.svg'
import DollarIcon from '../../../images/money.svg'


const GearItem = ({ usersGear, otherProfile }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuButtonRef = useRef()
  const dispatch = useDispatch()
  
  const linkToReverb = () => {
    if(usersGear.gear.reverb_slug) { 
      console.log("linking to Reverb", usersGear.gear.reverb_slug)
      window.open(`https://reverb.com/p/${usersGear.gear.reverb_slug}`)
    } 
  }
  
  const remove = () => {
    dispatch(removeGear(usersGear))
  }
  
  const openDetails = () => {
    dispatch(openGearDetailsModal(usersGear))
  }

  const openInfo = () => {
    dispatch(openGearInfoModal(usersGear))
  }
  
  const sellOnReverb = () => {
    
  }
  
  return (
    <div className="gear-item">
      <div className="image-panel">
        <img className="image" src={usersGear.gear.image_src}/>
        <div className={`overlay ${menuOpen ? 'open' : ''}`}>
          <div className="menu-button" ref={menuButtonRef} onClick={() => setMenuOpen(!menuOpen)}><MenuIcon/></div>
          <PopupMenu className="gear-item-menu" triggeringRef={menuButtonRef} closeCallback={() => setMenuOpen(false)} options={
          otherProfile ? 
          [
            { label: 'Buy it on Reverb', icon: ReverbIcon, onClick: linkToReverb },
            { label: 'View Gear Info', icon: InfoIcon, onClick: openInfo },
          ]
          :
          [
            { label: 'Buy it on Reverb', icon: ReverbIcon, onClick: linkToReverb },
            { label: 'Sell it on Reverb', icon: DollarIcon, onClick: sellOnReverb },
            { label: 'View Gear Info', icon: InfoIcon, onClick: openInfo },
            { label: 'Edit Gear Details', icon: EditIcon, onClick: openDetails },
            { label: 'Remove Gear', icon: TrashIcon, onClick: remove }
          ]
            
          }/>
        </div>
      </div>
      <div className="name">
        <p>{usersGear.gear.name}</p>
      </div>
    </div>
  )
}

const GearPanel = ({loading, gearType, usersGearItems, add, otherProfile}) => {
  return (
    <div className={`my-gear profile-content ${!loading && usersGearItems.length == 0 ? 'empty' : ''}`}>
      { loading && (
        <div className="loader-container" style={ { height: '200px' } }>
          <div className="loader"/>
        </div>
      )}
      { !loading && add && usersGearItems.length == 0 && (
        <div className="empty-content">
          <div className="icon"><FolderOffIcon/></div>
          <div>
            <h4>No {capitalizeEachWord(gearType)}s Yet!</h4>
            { !otherProfile && <p>Now add your first {gearType}</p> }
          </div>
          { !otherProfile && <div className="button button-primary" onClick={add}>+ Add My First {capitalizeEachWord(gearType)}</div> }
        </div>
      )}
      { !loading && !add && usersGearItems.length == 0 && (
        <div className="empty-content">
          <div className="icon"><FolderOffIcon/></div>
          <div>
            <h4>No Gear to Display</h4>
          </div>
        </div>
      )}
      { !loading && usersGearItems.length > 0 && (
        <div className="gear-items">
          { usersGearItems.map((usersGear, i) => {
            return (
              <GearItem usersGear={usersGear} key={i} otherProfile={otherProfile}/>
            )
          })}
        </div>
      )}
    </div>
  )
}

const MyGear = ({ editable, instruments, pedals, amps, other, otherProfile, plugins, gearCount, loadGear, unloadGear, loading, addInstrument, addPedal, addAmp, addOther, userId}) => {
  const [gearType, setGearType] = useState('instrument')

  useEffect(() => {
    console.log("loading Gear for user", userId)
    loadGear()
    return () => unloadGear()
  }, [userId])
  
  return (
    <div className="gear-container">
      <div className="profile-header-bar">
        <div className="profile-header">
          <h2>{ otherProfile ? '' : 'My ' }Gear</h2>
          { !loading && (
            <span className="count-badge">{gearCount}</span>
          )}
        </div>
        { !otherProfile && (
          <div className="button-group">
            <div className="button button-secondary" onClick={addInstrument}>+ Add Instrument</div>
            <div className="button button-secondary" onClick={addPedal}>+ Add Pedal </div>
            <div className="button button-secondary" onClick={addAmp}>+ Add Amp</div>
            <div className="button button-secondary" onClick={addOther}>+ Add Miscellaneous</div>
            <Link to="/explore/gear" className="button button-secondary">Explore<ArrowForwardIcon/></Link>
          </div> 
        )}
      </div>
      <div className="pill-menu pill-list" style={{marginBottom: '24px'}}>
        <a className={`pill blue-pill pill-with-badge ${gearType == 'instrument' ? 'active':''}`} onClick={() => setGearType('instrument')}>Instruments<span className="pill-badge">{instruments.length}</span></a>
        <a className={`pill blue-pill pill-with-badge ${gearType == 'pedal' ? 'active':''}`} onClick={() => setGearType('pedal')}>Pedals<span className="pill-badge">{pedals.length}</span></a>
        <a className={`pill blue-pill pill-with-badge ${gearType == 'amp' ? 'active':''}`} onClick={() => setGearType('amp')}>Amps<span className="pill-badge">{amps.length}</span></a>
        <a className={`pill blue-pill pill-with-badge ${gearType == 'other' ? 'active':''}`} onClick={() => setGearType('other')}>Miscellaneous<span className="pill-badge">{other.length}</span></a>
      </div>
      { (gearType == 'instrument') && <GearPanel loading={loading} gearType="instrument" usersGearItems={instruments} add={addInstrument} otherProfile={otherProfile}/> }
      { (gearType == 'pedal') && <GearPanel loading={loading} gearType="pedal" usersGearItems={pedals} add={addPedal} otherProfile={otherProfile}/> }
      { (gearType == 'amp') && <GearPanel loading={loading} gearType="amp" usersGearItems={amps} add={addAmp} otherProfile={otherProfile}/> }
      { (gearType == 'other') && <GearPanel loading={loading} gearType="miscellaneous item" usersGearItems={other} add={addOther} otherProfile={otherProfile}/> }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    editable: !ownProps.otherProfile,
    instruments: state.gear.instruments,
    pedals: state.gear.pedals,
    amps: state.gear.amps,
    other: state.gear.other,
    plugins: state.gear.plugins,
    gearCount: state.gear.totalCount,
    loading: state.gear.loading
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadGear: () => dispatch(loadGear(ownProps.otherProfile, ownProps.userId)),
    unloadGear: () => dispatch(unloadGear()),
    addInstrument: () => dispatch(addGear('instrument')),
    addPedal: () => dispatch(addGear('pedal')),
    addAmp: () => dispatch(addGear('amp')),
    addOther: () => dispatch(addGear('other'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyGear)