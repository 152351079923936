import React from "react"

function withNoneApplied(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)
    }

    render() {
      if (this.props.isEmpty) {
        return <p className="info-text">None Applied</p>
      } else {
        return <WrappedComponent {...this.props} />
      }
    }
  }
}

export default withNoneApplied
