import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { openGearSelectionModalFromForm } from '../actions/gearSelectionActions'

const gearSelectionField = ({ input, formName, gearType, multiple, backupLabel, meta: { touched, error, active } }) => {
  const dispatch = useDispatch()
  const [gear, setGear] = useState(undefined)
  
  useEffect(() => {
    if(input.value) {
      axios.get(`/api/v2/gears/${input.value}.json`).then((response) => {
        console.log("setting gear", response)
        setGear(response.data)
      })
    }
  }, [input.value])
  
  const openModal = () => {
    dispatch(openGearSelectionModalFromForm(formName, input.name, multiple, gearType))
  }
  
  return (
    <div className="gear-selection-field">
      <div className="image">
        { gear ? (
          <img src={gear.image_src}/>
        ) : (
          <div/>
        )}
      </div>
      <div className="info">
        <span className="name">{(gear && gear.name) || backupLabel}</span>
        <input {...input} type="hidden"/>
      </div>
      <div className="controls">
        <div className="open-button" onClick={openModal}>{gear ? 'Change' : 'Select'}</div>
      </div>
    </div>
  )
}

export { gearSelectionField }