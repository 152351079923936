const initialState = {
    category: 'users',
    users: [],
    signalChains: [],
    loading: true,
    searchParams: {},
    page: 1
}

function exploreReducer(state = initialState, action) {
  switch(action.type) {
    case 'FETCHING_EXPLORE_USERS':
      return {
        ...state,
        category: 'users',
        users: [],
        signalChains: [],
        loading: true,
        searchParams: action.params,
        page: 1
      }
    case 'SET_EXPLORE_USERS':
      return {
        ...state,
        users: action.users,
        loading: false
      }
    case 'FETCHING_EXPLORE_SIGNAL_CHAINS':
      return {
        ...state,
        category: 'signalChains',
        users: [],
        signalChains: [],
        loading: true,
        searchParams: action.params,
        page: 1
      }
    case 'SET_EXPLORE_SIGNAL_CHAINS':
      return {
        ...state,
        signalChains: action.signalChains,
        loading: false
      }
    default:
      return state
  }
}

export default exploreReducer