const timeFormatter = (timestampOrDate) => {
  if(!(timestampOrDate instanceof Date)) {
    timestampOrDate = new Date(timestampOrDate)
  }
  return timestampOrDate.toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})
}

const formatDateToYYYYMMDD = (date) => {
  console.log("date", date)
  if(typeof(date) === "string" || date instanceof String) {
    date = parseDateStringToDate(date)
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function parseDateStringToDate(dateString) {
  // Split the string into an array [month, day, year]
  const dateParts = dateString.split('/');

  // Ensure the array has three elements (month, day, year)
  if (dateParts.length === 3) {
    // Parse the array elements into integers
    const month = parseInt(dateParts[0], 10);
    const day = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    // Create a new Date object (months are zero-based in JavaScript)
    const parsedDate = new Date(year, month - 1, day);

    // Check if the parsed date is valid
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  }

  // Return null for invalid input
  return null;
}



const transformKeys = (obj, keyTransformFn) =>
  Object.keys(obj).reduce((acc, key) => {
    const transformedKey = keyTransformFn(key);
    acc[transformedKey] = obj[key];
    return acc;
  }, {});
  
const capitalizeEachWord = (inputString) => {
  // Check if the inputString is not empty
  if (inputString && typeof inputString === 'string') {
    return inputString
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    // Handle the case where input is not a non-empty string
    return inputString;
  }
}


const transformKeysCamelToSnake = (obj) => transformKeys(obj, camelToSnake)

const camelToSnake = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export { timeFormatter, transformKeys, transformKeysCamelToSnake, camelToSnake, capitalizeEachWord, formatDateToYYYYMMDD, parseDateStringToDate }