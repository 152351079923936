import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import gearSelection from '../../fieldRenderers/gearSelection'
import { submitGearSelection } from '../../actions/signalChainActions'

let GearForm = ({ handleSubmit, change, gearType, gearId, connectionId, multiple }) => {
  return (
    <form className="vertical-form" onSubmit={ handleSubmit }>
      <Field name="signal_chain[gear_ids]" component={gearSelection} gearType={gearType} gearId={gearId} connectionId={connectionId} multiple={multiple}/>
      <div className="buttons">
        <div className="form-group">
          <input className="select" name='submit' type='submit' value="Select" onClick={ () => change('signal_chain[scg_id]', gearId) }/>
        </div>
      </div>
    </form>
  )
}

GearForm = reduxForm({
  form: 'signal_chain'
})(GearForm)

GearForm = connect((state, ownProps) => ({
  initialValues: {
    signal_chain: {
      scg_id: ownProps.gearId
    }
  }
}))(GearForm)

const SelectGearModal = ({ back, skip, submit, gearType, gearId, connectionId, multiple, modalKey }) => {
  const headings = {
    'instrument': 'Instrument',
    'pedal': 'Pedal',
    'amp': 'Amp',
    'other': 'Miscellaneous Item'
  }

  return (
    <div className={ multiple ? "gear-selection-modal multiple" : "gear-selection-modal" }>
      <div className="modal-header">
        <h2>Select {headings[gearType].charAt(0).toUpperCase() + headings[gearType].slice(1)}{ multiple ? "s" : "" }</h2>
      </div>
      <GearForm key={modalKey} onSubmit={ submit } multiple={ multiple } gearType={ gearType } gearId={ gearId } connectionId={ connectionId } multiple={ multiple }/>
    </div>
  )
}

const selector = createStructuredSelector({
  gearType: state => state.signalChain.modalGearType,
  gearId: state => state.signalChain.modalGearId,
  connectionId: state => state.signalChain.modalConnectionId,
  multiple: state => state.signalChain.multipleInput,
  modalKey: state => state.signalChain.modalKey
})

const mapDispatchToProps = dispatch => {
  return {
    submit: params => dispatch(submitGearSelection(params)),
  }
}

export default connect(selector, mapDispatchToProps)(SelectGearModal)